import React, { Component } from 'react';
import styles from './index.module.css'

import majorIcon from '../../assets/images/schoolDetailImg/majorIcon.png'

export default class EnrollmentPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showData: this.props.enrollPlanData,
            showDataTitle: this.props.planBatchTitle,
            isShow: false,
            totalCount: this.props.total,
        }
    }

    onOpenChange = () => {
        this.props.showSpring('plans')
    }

    render() {
        const { showDataTitle, showData, totalCount, isShow } = this.state
        return (
            <div className={styles.body} style={isShow ? { height: '100%', width: '100%', position: 'absolute', top: '0px', left: '0px', overflow: 'hidden' } : {}}>
                {showData.length === 0 ? <div className={styles.nodata}>
                    暂无数据
                </div> : <div>
                        <div className={styles.batchEnrollPlan}>
                            <span>{showDataTitle}</span>
                            <img src={majorIcon} alt='' onClick={this.onOpenChange} />
                            <div>
                                总计划: {totalCount}
                            </div>
                        </div>
                        <div className={styles.cludeAll}>
                            {showData.map(item =>
                                <div className={styles.planBox} key={item.key}>
                                    <div className={styles.majorTitle}>
                                        <div><strong>{item.name}</strong></div>
                                        <div>代码: <i>{item.code}</i></div>
                                    </div>
                                    <div className={styles.majorInfo}>
                                        <span>计划数: <i>{item.planCount}</i></span>
                                        <span>学制: <i>{item.studyYear}</i></span>
                                        <span className={styles.price}>学费: <em>{item.fee}</em>元/年</span>
                                    </div>
                                    <div className={styles.majorInfo} style={{width:'100%',display:'block',clear:'both',lineHeight:'20px',paddingBottom:'20px'}}>
                                        <span><i>{item.subjectDesc+"【"+item.subjectList.join('+')+"】"}</i></span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={styles.reference}>
                            数据仅供参考
                        </div>
                    </div>}
            </div>
        )
    }
}