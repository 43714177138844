import React, { Component } from 'react';
import styles from './index.module.css'

export default class SearchFastPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            area: ''
        }
    }

    componentDidMount() {
        const area = sessionStorage.getItem('provinceTitle')
        if (area) {
            this.setState({
                area: area
            })
        }
    }

    gosearch = (keyword) => {
        this.props.parent.fastSearch(this, keyword)
    }
    gosearchArea=(area)=>{
        if(area==='') {
            alert('您未设置个人信息，无法查看周边大学')
        }else{
            this.props.parent.fastSearch(this, area)
        }
    }
    goUniversity=()=>{
        this.props.history.push('/collegeInquiry')
    }
    goMajor=()=>{
        this.props.history.push('/allMajorIntro')
    }

    render() {
        return (
            <div className={styles.body}>
                <div className={styles.quickFind}>
                    <span>快速查找</span>
                </div>
                <div className={styles.findCondition}>
                    <div className={styles.conditionItem}>
                        <h2>大学数据</h2>
                        <div className={styles.conditionTags}>
                            <span onClick={() => this.goUniversity()}>查学校</span>
                            <span onClick={() => this.goMajor()}>查专业</span>
                        </div>
                    </div>
                    <div className={styles.conditionItem}>
                        <h2>按地域查找</h2>
                        <div className={styles.conditionTags}>
                            <span onClick={() => this.gosearchArea(this.state.area)}>周边大学</span>
                            <span onClick={() => this.gosearch('北京')}>北京</span>
                            <span onClick={() => this.gosearch('上海')}>上海</span>
                            <span onClick={() => this.gosearch('广州')}>广州</span>
                            <span onClick={() => this.gosearch('深圳')}>深圳</span>
                        </div>
                    </div>
                    <div className={styles.conditionItem}>
                        <h2>按学校类别查找</h2>
                        <div className={styles.conditionTags}>
                            <span onClick={() => this.gosearch('双一流')}>双一流</span>
                            <span onClick={() => this.gosearch('211')}>211</span>
                            <span onClick={() => this.gosearch('985')}>985</span>
                        </div>
                    </div>
                    <div className={styles.conditionItem}>
                        <h2>按学校类型查找</h2>
                        <div className={styles.conditionTags}>
                            <span onClick={() => this.gosearch('综合类')}>综合类</span>
                            <span onClick={() => this.gosearch('师范类')}>师范类</span>
                            <span onClick={() => this.gosearch('财经类')}>财经类</span>
                            <span onClick={() => this.gosearch('政法类')}>政法类</span>
                            <span onClick={() => this.gosearch('理工类')}>理工类</span>
                            <span onClick={() => this.gosearch('医药类')}>医药类</span>
                        </div>
                    </div>
                    <div className={styles.conditionItem}>
                        <h2>按专业查找</h2>
                        <div className={styles.conditionTags}>
                            <span onClick={() => this.gosearch('土木工程')}>土木工程</span>
                            <span onClick={() => this.gosearch('计算机')}>计算机</span>
                            <span onClick={() => this.gosearch('工商管理')}>工商管理</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}