import React, { Component } from 'react';

export default class Map extends Component {
    constructor(props){
        super(props)
        this.state={

        }
    }

    componentDidMount(){
        let address = ['116.404','39.915']
        if(this.props.position!==''){
            address = this.props.position.split(',')
        }
        var map = new window.BMapGL.Map("container");
        var point = new window.BMapGL.Point(parseFloat(address[0]), parseFloat(address[1]));
        var marker = new window.BMapGL.Marker(point)
        map.addOverlay(marker);
        map.disableDragging(); 
        map.centerAndZoom(point, 15); 
        map.addEventListener('dblclick', () =>{
           this.goFullMap()
        });
    }

    goFullMap=()=>{
        this.props.history.push(`/fullMap/${this.props.position}`)
    }

    render(){
        return(
            <div id="container" style={{width:"100%",height:'100%'}}>

            </div>
        )
    }
}