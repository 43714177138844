import React, { Component } from 'react';
import styles from './index.module.css'

import Map from '../Map/index'

import openImg from '../../assets/images/schoolDetailImg/open.png'
import closeImg from '../../assets/images/schoolDetailImg/close.png'
import urlImg from '../../assets/images/schoolDetailImg/url.png'

export default class Introduction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            universityInfo: this.props.universityInfo,
            activeKey: [],
            showFullImg: false,
            showFullImgUrl: ''
        }
    }

    componentDidMount() {
        const contentHeight = window.screen.availHeight
        const contentWidth = window.screen.availWidth
        this.setState({
            contentHeight: contentHeight,
            contentWidth: contentWidth
        })
    }

    openClick = (decide) => {
        const { activeKey } = this.state
        let newActiveKey = activeKey
        if (activeKey.indexOf(decide) > -1) {
            newActiveKey.splice(activeKey.indexOf(decide), 1)
            this.setState({
                activeKey: newActiveKey
            })
        } else {
            newActiveKey.push(decide)
            this.setState({
                activeKey: newActiveKey
            })
        }
    }

    goWebsite = () => {
        this.props.history.push({ pathname: '/website', query: { url: this.state.universityInfo.url, name: this.state.universityInfo.name } })
    }

    imgClick = (item) => {
        this.setState({
            showFullImg: true,
            showFullImgUrl: item
        })
    }
    fullImgBoxClick = (e) => {
        e.stopPropagation()
        this.setState({
            showFullImg: false,
            showFullImgUrl: ''
        })
    }

    render() {
        const { universityInfo, activeKey, showFullImgUrl, showFullImg, contentWidth, contentHeight } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.schoolRank}>
                    <h2>学校排名</h2>
                    <div className={styles.sourceRank}>
                        <span>生源质量: {universityInfo.pupilsRank}</span>
                        <span>师资排名: {universityInfo.teacherRank}</span>
                    </div>
                    <div className={styles.websiteUrl}>
                        <img src={urlImg} alt='' />
                        <span onClick={this.goWebsite}>学校官网: {universityInfo.url} (点击打开)</span>
                    </div>
                    <div className={styles.cateRate}>
                        <ul>
                            <li className={styles.bgi1}>
                                <span>学士评估</span>
                                <div>{universityInfo.scholarRank}</div>
                            </li>
                            <li className={styles.bgi2}>
                                <span>就业排名</span>
                                <div>{universityInfo.workRank}</div>
                            </li>
                            <li className={styles.bgi3}>
                                <span>环境评级</span>
                                <div>{universityInfo.environmentalLevel}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.shcoolProfile}>
                    <h2>学校简介</h2>
                    <div className={styles.detail}>
                        <div className={activeKey.indexOf('xxjj') > -1 ? styles.detailContentOpen : styles.detailContent}>
                            {universityInfo.introduction}
                        </div>
                        <div className={styles.detailOpen} onClick={() => this.openClick('xxjj')}>
                            <span>{activeKey.indexOf('xxjj') > -1 ? '收起' : '展开'}</span>
                            <img src={activeKey.indexOf('xxjj') > -1 ? closeImg : openImg} alt='' />
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
                <div className={styles.shcoolProfile}>
                    <h2>招生简章</h2>
                    <div className={styles.detail}>
                        <div className={activeKey.indexOf('zsjz') > -1 ? styles.detailContentOpen : styles.detailContent}>
                            {universityInfo.regulations}
                        </div>
                        <div className={styles.detailOpen} onClick={() => this.openClick('zsjz')}>
                            <span>{activeKey.indexOf('zsjz') > -1 ? '收起' : '展开'}</span>
                            <img src={activeKey.indexOf('zsjz') > -1 ? closeImg : openImg} alt='' />
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
                <div className={styles.shcoolProfile}>
                    <h2>学校环境</h2>
                    <div className={styles.envTags}>
                        <span>在校人数 : {universityInfo.numberOfPeople}</span>
                        <span>校园面积 : {universityInfo.area}</span>
                        <span>建校时间 : {universityInfo.createYear}</span>
                        <span>馆藏图书 : {universityInfo.numberOfBook}</span>
                        <span>宿舍情况 : {universityInfo.apratIntro}</span>
                        <span>食堂情况 : {universityInfo.foodIntro}</span>
                    </div>
                    <div className={styles.imgBox}>
                        <ul style={{ height: universityInfo.images.length > 0 ? '80px' : '0' }}>
                            {universityInfo.images.map((item, index) => <li key={index} onClick={() => this.imgClick(item)}>
                                <img src={item} alt='' />
                            </li>)}
                        </ul>
                    </div>
                </div>
                <div className={styles.shcoolProfile}>
                    <h2>学校发展</h2>
                    <div className={styles.envTags}>
                        <span>就业指数 : {universityInfo.workIndex}%</span>
                        <span>创业指数 : {universityInfo.createIndex}%</span>
                        <span>考研指数 : {universityInfo.masterIndex}%</span>
                        <span>留学指数 : {universityInfo.abroadIndex}%</span>
                        <span>硕士点 : {universityInfo.masterPoint}</span>
                        <span>博士点 : {universityInfo.doctorPoint}</span>
                    </div>
                </div>
                <div className={styles.shcoolProfile} style={{ display:universityInfo.position===''?'none' :'block'  }}>
                    <h2>地理位置</h2>
                    <div className={styles.location}>{universityInfo.address} ({universityInfo.provinceText+universityInfo.cityText})</div>
                    <div className={styles.mapBox}>
                        <Map position={universityInfo.position} history={this.props.history}></Map>
                    </div>
                </div>
                <div className={styles.fullImgBox} style={{ width: showFullImg ? contentWidth : 0, height: showFullImg ? contentHeight : 0 }} onClick={(e) => this.fullImgBoxClick(e)}>
                    <img alt="" src={showFullImgUrl} />
                </div>
            </div>
        )
    }
}