/*eslint-disable */
import React, { Component } from 'react';
import { NavBar, Empty, Grid, Card, Toast, Dialog } from 'antd-mobile';
import { axiosPost, axiosPostPay } from '../../Utils/RequestUtil';
import moment from 'moment';
import styles from './index.module.css';
import orderback from '../../assets/images/schoolDetailImg/orderback.png'
import aimg from '../../assets/images/schoolDetailImg/a.png'
import bimg from '../../assets/images/schoolDetailImg/b.png'
import cimg from '../../assets/images/schoolDetailImg/c.png'
import dimg from '../../assets/images/schoolDetailImg/d.png'
const height = window.innerHeight;
export default class AchievementOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configs: [],
            activeKey: '',
            configItem: null,
            payBody: null,
            endTime: ''
        }
    }
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        const body = {
            RelKey: sessionStorage.getItem('openId'),
            UserKey: sessionStorage.getItem('openId')
        }
        axiosPost(18, body, 'University', (res) => {
            Toast.clear();
            if (res.code === 0) {
                this.setState({ configs: res.data.list, activeKey: res.data.list[0].key, configItem: res.data.list[0], endTime: res.data.EndTime });
                return;
            }
            return;
        })
    }
    goback = () => {
        this.props.history.go(-1)
    }
    onItemClick = (item) => {
        this.setState({ activeKey: item.key, configItem: item })
    }
    onPayClick = () => {
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        let body = {
            openid: sessionStorage.getItem('openId'),
            config: this.state.configItem.key,
            order: ''
        }
        if (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined') {
            axiosPostPay(20, '', body, 'Wechat', (res) => {
                Toast.clear();
                if (res.code === 0) {
                    this.setState({ payBody: res.data }, () => {
                        this.pay();
                    });
                    return;
                }
                Toast.show({ icon: 'fail', content: res.message });
                return;
            });
            return;
        }
        body = {
            user: sessionStorage.getItem('openId'),
            config: this.state.configItem.key,
            order: ''
        }
        axiosPostPay(17, '', body, 'Wechat', (res) => {
            Toast.clear();
            if (res.code === 1) {
                window.location.href=res.data.resultPayUrl;
                return;
            }
            Toast.show({ icon: 'fail', content: res.message });
            return;
        });
        return;
    }
    pay = () => {
        if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', this.payApiCall, false);
            }
            else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', this.payApiCall);
                document.attachEvent('onWeixinJSBridgeReady', this.payApiCall);
            }
        }
        else {
            this.payApiCall()
        }
    }
    payApiCall = () => {
        WeixinJSBridge.invoke('getBrandWCPayRequest', JSON.parse(this.state.payBody.json), (res) => {
            this.queryOrder();
        });
    }
    queryOrder = () => {
        Toast.show({
            icon: 'loading',
            content: '正在查询',
            duration: 0,
            maskClickable: false
        })
        setTimeout(() => {
            const body = {
                order: this.state.payBody.order
            }
            axiosPostPay(21, '', body, 'Wechat', (res) => {
                Toast.clear();
                if (res.code === 0) {
                    Dialog.confirm({
                        content: res.message + '，返回上一页？',
                        onConfirm: () => {
                            this.goback();
                        },
                    });
                    return;
                }
                Toast.show({ icon: 'fail', content: res.message });
                return;
            })
        }, 3000);
    }
    render() {
        return <div className={styles.body}>
            <NavBar style={{ backgroundColor: '#449bfe', color: '#fff', height: '50px', lineHeight: '50px', borderColor: '#449bfe' }} onBack={this.goback}>
                订购服务
            </NavBar>
            <div style={{ padding: 10, backgroundImage: 'url(' + orderback + ')', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0', height: height - 50 + 'px' }}>
                {
                    this.state.configs.length > 0
                        ? <div>
                            <div style={{ height: height - 130 + 'px', overflow: 'auto' }}>
                                <div style={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}><span style={{ lineHeight: '40px' }}>开通VIP&nbsp;&nbsp;&nbsp;&nbsp;精准选大学</span>{this.state.endTime !== '' ? <span style={{ fontSize: '14px', lineHeight: '25px' }}><br />已订购有效期至{moment(this.state.endTime).format("YYYY年MM月DD日")}</span> : null}</div>
                                <div style={{ marginTop: 30 }}>
                                    <Grid columns={2} gap={10}>
                                        {
                                            this.state.configs.map(item => <Grid.Item key={item.key}>
                                                <Card onClick={() => { this.onItemClick(item) }} className={this.state.activeKey === item.key ? styles.activeCard : styles.normalCard}>
                                                    <div className={this.state.activeKey === item.key ? styles.activeCardName : styles.normalCardName} style={{ fontSize: '14px', fontWeight: 'bold', lineHeight: '30px' }}>{item.name}</div>
                                                    <div className={this.state.activeKey === item.key ? styles.activeCardMoney : styles.normalCardMoney} style={{ fontSize: '32px', lineHeight: '40px' }}><span style={{ fontSize: '14px' }}>￥</span>{item.money}</div>
                                                    <div className={this.state.activeKey === item.key ? styles.activeCardDate : styles.normalCardDate}>有效期至{moment(item.endTime).format("YYYY年MM月DD日")}</div>
                                                </Card>
                                            </Grid.Item>)
                                        }
                                    </Grid>
                                </div>
                                <div style={{ marginTop: 30 }}>
                                    <Grid columns={1} gap={10}>
                                        {
                                            this.state.configs.map((item, index) => {
                                                let img = aimg;
                                                if (index === 1) {
                                                    img = bimg;
                                                }
                                                if (index === 2) {
                                                    img = cimg;
                                                }
                                                if (index === 3) {
                                                    img = dimg;
                                                }
                                                return <Grid.Item style={{ marginTop: 10 }} key={'d' + item.key}>
                                                    <div style={{ float: 'left' }}>
                                                        <img src={img} alt="" style={{ height: '50px' }} />
                                                    </div>
                                                    <div style={{ lineHeight: '25px', fontSize: '14px', paddingLeft: '53px' }}>{item.name}：{item.desc}</div>
                                                </Grid.Item>
                                            })
                                        }
                                    </Grid>
                                </div>
                            </div>
                            <div className={styles.orderBtn} onClick={() => { this.onPayClick() }}>
                                <Grid columns={2}>
                                    <Grid.Item style={{ textAlign: 'left', paddingLeft: 30, fontSize: '16px', color: '#000', lineHeight: '70px' }}>
                                        原价：<span style={{ textDecoration: 'line-through' }}>￥{this.state.configItem.old}</span>
                                    </Grid.Item>
                                    <Grid.Item style={{ textAlign: 'right', paddingRight: 30, fontSize: '18px', color: '#fff', fontWeight: 'bold', lineHeight: '60px' }}>
                                        <span style={{ fontSize: '14px' }}>￥</span>{this.state.configItem.money}&nbsp;&nbsp;立即开通
                                    </Grid.Item>
                                </Grid>
                            </div>
                        </div>
                        : <Empty
                            style={{ padding: '64px 0' }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据'
                        />
                }
            </div>
        </div>
    }
}