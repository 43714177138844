import React, { Component } from 'react';
import { axiosPost } from '../../Utils/RequestUtil'
import { Toast } from 'antd-mobile'
import styles from './index.module.css'

import majoropen from '../../assets/images/schoolDetailImg/majoropen.png'
import majorclose from '../../assets/images/schoolDetailImg/majorclose.png'

export default class AllMajorZK extends Component {
    constructor() {
        super()
        this.state = {
            majorTreeData: [],
            allListData:[],
            activeKeys: []
        }
    }

    componentDidMount() {
        this.getMajorBKData()
    }

    getMajorBKData = () => {
        const params = {
            flag: 'U-ZKZY'
        }
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(6, params, 'Data', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.setState({
                    majorTreeData: res.data.list
                }, () => this.renderList())
            }

        })
    }
    componentWillUnmount() {
        Toast.clear()
        this.setState = () => {
            return;
        };
    }

    renderList = () => {
        const { majorTreeData } = this.state
        const dataList = []
        const addData = (data, level) => {
            for (let i = 0; i < data.length; i++) {
                let newItem = data[i]
                newItem.level = level
                dataList.push(newItem)
                if (data[i].children.length > 0) {
                    let childLevel = level + 1
                    addData(data[i].children, childLevel)
                }
            }
        }
        addData(majorTreeData, 1)
        this.setState({
            allListData: dataList
        })
    }

    listClick = (item) => {
        const { activeKeys } = this.state
        let newActiveKeys = []
        if (item.children.length > 0 && activeKeys.indexOf(item.CodeALLID) > -1) {
            const itemIndex = activeKeys.indexOf(item.CodeALLID)
            newActiveKeys = activeKeys.slice(0, itemIndex)
            this.setState({
                activeKeys: newActiveKeys
            })
        } else if (item.children.length > 0 && newActiveKeys.indexOf(item.CodeALLID) === -1) {
            newActiveKeys = [...activeKeys, item.CodeALLID]
            this.setState({
                activeKeys: newActiveKeys
            })
        } else {
            this.props.history.push(`/majorDetail/${item.CodeALLID}/${item.CodeAllName}`)
        }
    }

    render() {
        const {activeKeys,allListData} = this.state
        return (
            <div className={styles.body}>
                <ul>
                    {allListData.map(item =>
                        <li
                            style={{ display: item.CodeALLID === item.PrtCode ? 'block' : activeKeys.indexOf(item.PrtCode) > -1 ? 'block' : 'none', paddingLeft: 20 * item.level }}
                            key={item.CodeALLID}
                            onClick={() => this.listClick(item)}
                        >
                            {<img src={item.children.length === 0 ? '' : activeKeys.indexOf(item.CodeALLID) > -1 ? majoropen : majorclose} alt=''
                                style={{
                                    width: activeKeys.indexOf(item.CodeALLID) > -1 ? '13px' : '8px',
                                    height: activeKeys.indexOf(item.CodeALLID) > -1 ? '8px' : '13px',
                                    marginBottom: activeKeys.indexOf(item.CodeALLID) > -1 ? '0' : '3px'
                                }}
                            />}
                            {item.CodeAllName}
                        </li>)}
                </ul>
            </div>
        )
    }
}