import CryptoJS from 'crypto-js';
import md5 from 'js-md5';
const privateKey = 'JHXKJHZN';

export function encryptByDES(message) {
  const keyHex = CryptoJS.enc.Utf8.parse(privateKey);
  var base64String = 'EjRWeJCrze8=';
  var iv = CryptoJS.enc.Base64.parse(base64String);
  const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString();
}

export function sign(params, time) {
  var md5Str = '';
  md5Str += time;
  if (params.parameters && params.parameters !== null && params.parameters !== undefined) {
    Object.keys(params.parameters).forEach(function (key) {
      if (params.parameters[key] instanceof Array) {
        if(params.parameters[key].length>0){
          for (var i = 0; i < params.parameters[key].length; i++) {
            if(params.parameters[key][i].toString()==='[object Object]'){
              if (i === 0) {
                md5Str += "[";
                md5Str+=objsign(params.parameters[key][i]);
              }
              else{
                md5Str+=','+objsign(params.parameters[key][i]);
              }
              if (i === params.parameters[key].length - 1) {
                md5Str += "]";
              }
            }
            else{
              if (i === 0) {
                md5Str += "[";
                md5Str += '"' + params.parameters[key][i] + '"';
              }
              else {
                md5Str += ',"' + params.parameters[key][i] + '"';
              }
              if (i === params.parameters[key].length - 1) {
                md5Str += "]";
              }
            }
          }
        }
        else{
          md5Str += "[]";
        }
      }
      else {
        if (params.parameters[key] !== null && params.parameters[key] !== undefined && params.parameters[key] !== "undefined" && params.parameters[key] !== "null") {
          md5Str += params.parameters[key];
        }
      }
    });
  }
  md5Str += privateKey;
  return md5(md5Str).toUpperCase();
}

function objsign(params) {
  let md5Str='';
  md5Str+="{";
  if (params && params !== null && params !== undefined) {
    Object.keys(params).forEach(function (key,index) {
      if (params[key] !== null && params[key] !== undefined && params[key] !== "undefined" && params[key] !== "null") {
        if(index===0){
          md5Str += '"'+key+'":"'+params[key]+'"';
        }
        else{
          md5Str += ',"'+key+'":"'+params[key]+'"';
        }
      }
    });
    md5Str+="}";
  }
  return md5Str;
}