import React, { Component } from 'react';
import { axiosPost } from '../../Utils/RequestUtil'
import { Toast } from 'antd-mobile'
import styles from './index.module.css'

import locationImg from '../../assets/images/collegeInquiryImg/location.png'
import rankImg from '../../assets/images/collegeInquiryImg/rank.png'
import categoryImg from '../../assets/images/collegeInquiryImg/category.png'

export default class MajorHasSchool extends Component {
    constructor(props) {
        super(props)
        this.bodyRef = React.createRef();
        this.state = {
            majorKey: this.props.majorKey,
            pageIndex: 0,
            size: 10,
            universityData: [],
            universityCount: 0,
            dataLoadedFlag: 0,
            contentWidth:0
        }
    }

    componentDidMount() {
        const contentWidth = window.screen.availWidth
        this.setState({
            contentWidth:contentWidth
        })
        this.getUniversityData()
        window.addEventListener('scroll', this.handleScroll, false)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false)
        this.setState = () => {
            return;
        };
    }

    handleScroll = () => {
        if (this.bodyRef.current.clientHeight + 190 === window.scrollY + window.innerHeight) {
            if (this.state.dataLoadedFlag === 0) {
                this.setState({
                    pageIndex: this.state.pageIndex + 1
                }, () => {
                    this.getUniversityAdd()
                }
                )
            }
        }
    }

    getUniversityData() {
        const { majorKey, size } = this.state
        const params = {
            key: majorKey,
            index: 0,
            size: size
        }
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(10, params, 'University', (res) => {
            Toast.clear()
            if (res.data.list) {
                this.setState({
                    universityData: res.data.list,
                    universityCount: res.data.count,
                    dataLoadedFlag: res.code
                })
            }
        })
    }

    getUniversityAdd() {
        const { majorKey, universityData, pageIndex, size } = this.state
        const params = {
            key: majorKey,
            index: pageIndex,
            size: size
        }
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(10, params, 'University', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.setState({
                    universityData: [...universityData, ...res.data.list],
                })
            } else {
                this.setState({
                    dataLoadedFlag: res.code
                })
            }
        })
    }

    schoolInfo = (item) => {
        this.props.history.push(`/schoolInfo/${item.key}/${item.name}/${'1'}`)
    }

    render() {
        const { universityData, universityCount ,contentWidth} = this.state
        return (
            <div className={styles.body} ref={this.bodyRef}>
                <h2 className={styles.totalNum}>共{universityCount}所院校</h2>
                <ul>
                    {universityData ? universityData.map(item =>
                        <li onClick={() => this.schoolInfo(item)} key={item.key}>
                            <div className={styles.allClude}>
                                <div className={styles.flexLeft}>
                                    <div className={styles.logoBox}>
                                        <img src={item.logo} alt='' />
                                    </div>
                                    <div className={styles.schoolInfo} style={{width:contentWidth-160}}>
                                        <div className={styles.titleAndRank}>
                                            <span style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>{item.name}</span>
                                            <div className={styles.schoolRank}>
                                                <img src={rankImg} alt='' />
                                                <span>{item.rank}</span>
                                            </div>
                                        </div>
                                        <div className={styles.schoolTags}>
                                            {item.tagsText.map(child => <span key={child}>{child}</span>)}
                                        </div>
                                        <div className={styles.schoolCateLocal}>
                                            <span><img src={categoryImg} alt='' />{item.categoryText}</span>
                                            <span><img src={locationImg} alt='' />{item.provinceText+item.cityText}</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </li>
                    ) : ''}
                </ul>
            </div>
        )
    }
}