import React, { Component } from 'react';
import styles from './index.module.css';
import { AddOutline, RightOutline } from 'antd-mobile-icons'
import { Card, Toast, NavBar, Space, Empty, SwipeAction, Grid, Tag, Dialog } from 'antd-mobile';
import { axiosPost } from '../../Utils/RequestUtil';
import pageback from '../../assets/images/homeImg/pageback.png'
const height = window.innerHeight;
export default class Achievement extends Component {
    constructor(props) {
        super(props)
        this.bodyRef = React.createRef();
        this.state = {
            data: [],
            provinceData: []
        }
    }
    componentDidMount = () => {
        this.getData();
    }
    getData = () => {
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        const openId = sessionStorage.getItem('openId');
        const body = { RelKey: openId };
        axiosPost(14, body, 'University', (res) => {
            Toast.clear();
            if (res.code === 0) {
                let data = [];
                res.data.list.forEach(item => {
                    item.Total = item.Language + item.Mathematics + item.English + item.Physics + item.Chemistry + item.Biology + item.Politics + item.History + item.Geography
                    data.push(item);
                })
                this.setState({ data: data });
                return;
            }
            this.setState({ data: [] });
            return;
        })
    }
    goback = () => {
        this.props.history.go(-1)
    }
    onItemClick = (item) => {
        this.props.history.push(`/achievementDetail`, item)
    }
    onAddClick = () => {
        this.props.history.push(`/achievementAdd`, null)
    }
    onEidtClick = (item) => {
        this.props.history.push(`/achievementAdd`, item)
    }
    onRemoveClick = (item) => {
        Dialog.confirm({
            content: '确定删除吗？',
            onConfirm: () => {
                const openId = sessionStorage.getItem('openId');
                const body = {
                    RelKey: openId,
                    achievementKey: item.Id
                }
                axiosPost(20, body, 'University', (res) => {
                    if (res.code === 0) {
                        this.getData();
                        return;
                    }
                    Toast.show({
                        icon: 'fail',
                        content: res.message
                    })
                    return;
                })
            },
        })
    }
    render() {
        const right = (
            <div style={{ fontSize: 24 }}>
                <Space style={{ '--gap': '16px' }}>
                    <div onClick={() => { this.onAddClick() }}><AddOutline /></div>
                </Space>
            </div>
        )
        return <div className={styles.body} ref={this.bodyRef}>
            <NavBar style={{ backgroundColor: '#449bfe', color: '#fff', height: '50px', lineHeight: '50px', borderColor: '#449bfe' }} right={right} onBack={this.goback}>
                成绩列表
            </NavBar>
            <div style={{ padding: 10, backgroundColor: '#f0f0f0', height: height - 50 + 'px', overflow: 'auto', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                {
                    this.state.data.length > 0
                        ? this.state.data.map(item =>
                            <SwipeAction
                                style={{ border: '0px solid #ddd', marginBottom: '15px', borderRadius: '5px 5px 5px 5px',boxShadow: '0px 6px 5px #ddd' }}
                                rightActions={[
                                    {
                                        key: 'edit',
                                        text: '修改',
                                        color: 'primary',
                                        onClick: () => {
                                            this.onEidtClick(item)
                                        },
                                    },
                                    {
                                        key: 'delete',
                                        text: '删除',
                                        color: 'danger',
                                        onClick: () => {
                                            this.onRemoveClick(item)
                                        },
                                    },
                                ]}
                            >
                                <Card
                                    key={item.Id}
                                    title={item.Title}
                                    extra={<RightOutline />}
                                    onClick={() => { this.onItemClick(item) }}>
                                    <Grid columns={2} gap={10}>
                                        <Grid.Item>
                                            <Grid columns={1}>
                                                <Grid.Item>
                                                    {item.Year}
                                                </Grid.Item>
                                            </Grid>
                                            <Grid columns={1} style={{ marginTop: 10 }}>
                                                <Grid.Item >
                                                    {item.Type === "01" ? <Tag style={{ padding: 4 }} color={'#2db7f5'}>{item.ProvinceText + ',' + item.TypeText}</Tag> : <Tag style={{ padding: 4 }} color={'#108ee9'}>{item.ProvinceText + ',' + item.TypeText}</Tag>}
                                                </Grid.Item>
                                            </Grid>
                                        </Grid.Item>
                                        <Grid.Item style={{ fontSize: '32px', textAlign: 'right', lineHeight: '56px', color: '#87d068', fontWeight: 'bold' }}>
                                            {item.Total}
                                        </Grid.Item>
                                    </Grid>
                                    <Grid columns={3}>
                                        <Grid.Item span={2} style={{ fontSize: '14px', textAlign: 'left', color: '#000', lineHeight: '30px' }}>
                                            预测位次：{item.Lowrank}
                                        </Grid.Item>
                                        <Grid.Item span={1} style={{ fontSize: '8px', textAlign: 'right', color: '#999', lineHeight: '30px' }} >
                                            左滑可修改
                                        </Grid.Item>
                                    </Grid>
                                </Card>
                            </SwipeAction>
                        )
                        : <Empty
                            style={{ padding: '64px 0' }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据'
                        />
                }
            </div>
        </div>

    }
}