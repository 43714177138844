import React, { Component } from 'react';
import { axiosPost } from '../../Utils/RequestUtil'
import { Toast } from 'antd-mobile'
import styles from './index.module.css'

import majoropen from '../../assets/images/schoolDetailImg/majoropen.png'
import majorclose from '../../assets/images/schoolDetailImg/majorclose.png'

export default class Major extends Component {
    constructor() {
        super()
        this.state = {
            majorTreeData: [],
            allListData: [],
            activeKeys: []
        }
    }

    componentDidMount() {
        const {universityKey} = this.props
        const params = {
            key: universityKey
        }
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(2, params, 'University', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.setState({
                    majorTreeData: res.data.specialities
                }, () => this.renderList())
            }

        })

    }

    componentWillUnmount() {
        Toast.clear()
        this.setState = () => {
            return;
        };
    }

    renderList = () => {
        const { majorTreeData } = this.state
        const dataList = []
        const addData = (data, level) => {
            for (let i = 0; i < data.length; i++) {
                let newItem = data[i]
                newItem.level = level
                dataList.push(newItem)
                if (data[i].childrens.length > 0) {
                    let childLevel = level + 1
                    addData(data[i].childrens, childLevel)
                }
            }
        }
        addData(majorTreeData, 1)
        this.setState({
            allListData: dataList
        })
    }

    listClick = (item) => {
        const { activeKeys } = this.state
        let newActiveKeys = []
        if (item.childrens.length > 0 && activeKeys.indexOf(item.code) > -1) {
            const itemIndex = activeKeys.indexOf(item.code)
            newActiveKeys = activeKeys.slice(0, itemIndex)
            this.setState({
                activeKeys: newActiveKeys
            })
        } else if (item.childrens.length > 0 && newActiveKeys.indexOf(item.code) === -1) {
            newActiveKeys = [...activeKeys, item.code]
            this.setState({
                activeKeys: newActiveKeys
            })
        } else {
            this.props.history.push(`/majorDetail/${item.code}/${item.name}`)
        }
    }

    render() {
        const { allListData, activeKeys } = this.state
        return (
            <div className={styles.body}>
                <ul>
                    {allListData.map(item =>
                        <li
                            style={{ display: item.code === item.parent ? 'block' : activeKeys.indexOf(item.parent) > -1 ? 'block' : 'none', paddingLeft: 20 * item.level }}
                            key={item.code}
                            onClick={() => this.listClick(item)}
                        >
                            {<img src={item.childrens.length === 0 ? '' : activeKeys.indexOf(item.code) > -1 ? majoropen : majorclose} alt=''
                                style={{
                                    width: activeKeys.indexOf(item.code) > -1 ? '13px' : '8px',
                                    height: activeKeys.indexOf(item.code) > -1 ? '8px' : '13px',
                                    marginBottom: activeKeys.indexOf(item.code) > -1 ? '0' : '3px'
                                }}
                            />}
                            {item.name}
                            {item.childrens.length>0?<span className={styles.rightTotal}>({item.total})</span>:''}
                        </li>)}
                </ul>
            </div>
        )
    }
}