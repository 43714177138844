import React, { Component } from 'react';
import { axiosPost } from '../../Utils/RequestUtil'
import { Toast } from 'antd-mobile'
import styles from './index.module.css'

import gobackImg from '../../assets/images/collegeInquiryImg/goback.png'
import dropdownImg from '../../assets/images/collegeInquiryImg/dropdown.png'
import locationImg from '../../assets/images/collegeInquiryImg/location.png'
import rankImg from '../../assets/images/collegeInquiryImg/rank.png'
import categoryImg from '../../assets/images/collegeInquiryImg/category.png'
import pageback from '../../assets/images/homeImg/pageback.png'

export default class CollegeInquiry extends Component {
    constructor() {
        super()
        this.bodyRef = React.createRef();
        this.state = {
            contentHeight: 0,
            contentWidth: 0,
            cengjiData: [],
            provinceData: [],
            cateData: [],
            universityData: [],
            province: '',
            tag: '',
            keyword: '',
            type: '',
            pageIndex: 0,
            size: 10,
            isShow: false,
            showSymbol: '',
            showData: [],
            provinceTitle: '按地域',
            tagTitle: '按级别',
            typeTitle: '按类别',
            dataLoadingCompleted: 0,
            dom: null,
        }
    }
    scoRef = React.createRef();
    componentDidMount() {
        let contentHeight = window.innerHeight - 110
        let contentWidth = window.screen.availWidth
        let isChoosed = sessionStorage.getItem('isBack')
        if (isChoosed !== null) {
            let chooseProTil = sessionStorage.getItem('chooseProTil')
            let chooseProId = sessionStorage.getItem('chooseProId')
            let chooseTypeTil = sessionStorage.getItem('chooseTypeTil')
            let chooseTypeId = sessionStorage.getItem('chooseTypeId')
            let chooseTagTil = sessionStorage.getItem('chooseTagTil')
            let chooseTagId = sessionStorage.getItem('chooseTagId')
            if (chooseProTil !== null) {
                this.setState({
                    provinceTitle: chooseProTil
                })
            }
            if (chooseProId !== null) {
                this.setState({
                    province: chooseProId
                })
            }
            if (chooseTypeTil !== null) {
                this.setState({
                    typeTitle: chooseTypeTil
                })
            }
            if (chooseTypeId !== null) {
                this.setState({
                    type: chooseTypeId
                })
            }
            if (chooseTagTil !== null) {
                this.setState({
                    typeTitle: chooseTagTil
                })
            }
            if (chooseTagId !== null) {
                this.setState({
                    tag: chooseTagId
                })
            }
        }
        this.setState({
            contentHeight: contentHeight,
            contentWidth: contentWidth
        })
        this.getUniversity()
        this.getProvince()
        this.getlCateData()
        this.getCengjiData()
    }

    componentWillUnmount() {
        sessionStorage.setItem('isBack', '00')
        this.setState = () => {
            return;
        };
    }

    getUniversity() {
        this.setState({ pageIndex: 0 }, () => {
            let isChoosed = sessionStorage.getItem('isBack')
            let province
            let tag
            let type
            if (isChoosed !== undefined) {
                province = sessionStorage.getItem('chooseProId')
                type = sessionStorage.getItem('chooseTypeId')
                tag = sessionStorage.getItem('chooseTagId')
            } else {
                province = this.state.province
                type = this.state.type
                tag = this.state.tag
            }
            const { keyword, size } = this.state
            const params = {
                province: province,
                tag: tag,
                keyword: keyword,
                type: type,
                index: this.state.pageIndex,
                size: size
            }
            Toast.show({
                icon: 'loading',
                content: '数据加载中',
                duration: 0,
                maskClickable: false
            })
            axiosPost(0, params, 'University', (res) => {
                Toast.clear()
                if (res.data.list) {
                    this.setState({
                        universityData: res.data.list,
                        dataLoadingCompleted: res.code
                    })
                } else {
                    this.setState({
                        universityData: [],
                    })
                }
            })
            return;
        })
    }

    async getProvince() {
        const params = {
            flag: 'U-XZQH'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.data.list) {
                this.setState({
                    provinceData: res.data.list
                })
            }
        })

    }
    async getlCateData() {
        const params = {
            flag: 'U-XXFL'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.data.list) {
                this.setState({
                    cateData: res.data.list
                })
            }
        })
    }

    async getCengjiData() {
        const params = {
            flag: 'U-XXTAG'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.data.list) {
                this.setState({
                    cengjiData: res.data.list
                })
            }
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    schoolInfo = (item) => {
        this.props.history.push(`/schoolInfo/${item.key}/${item.name}/${'1'}`)
    }

    conditionCateClick = (decide) => {
        const { showSymbol } = this.state
        if (showSymbol !== decide) {
            this.setState({
                isShow: true,
                showData: decide === '1' ? this.state.provinceData : decide === '2' ? this.state.cateData : this.state.cengjiData,
                showSymbol: decide
            })
        } else {
            this.setState({
                isShow: false,
                showData: [],
                showSymbol: ''
            })
        }
    }

    springClick = () => {
        this.setState({
            isShow: false,
            showSymbol: ''
        })
    }

    queryListItemClick = (item) => {
        const { showSymbol } = this.state
        if (showSymbol === '1') {
            sessionStorage.setItem('chooseProTil', item.CodeName)
            sessionStorage.setItem('chooseProId', item.CodeALLID)
            this.setState({
                isShow: false,
                showSymbol: '',
                pageIndex: 0,
                provinceTitle: item.CodeName,
                province: item.CodeALLID,
                universityData: []
            }, () => this.getUniversity())
        } else if (showSymbol === '2') {
            sessionStorage.setItem('chooseTypeTil', item.CodeName)
            sessionStorage.setItem('chooseTypeId', item.CodeALLID)
            this.setState({
                isShow: false,
                showSymbol: '',
                pageIndex: 0,
                typeTitle: item.CodeName,
                type: item.CodeALLID,
                universityData: []
            }, () => this.getUniversity())
        } else {
            sessionStorage.setItem('chooseTagTil', item.CodeName)
            sessionStorage.setItem('chooseTagId', item.CodeALLID)
            this.setState({
                isShow: false,
                showSymbol: '',
                pageIndex: 0,
                tagTitle: item.CodeName,
                tag: item.CodeALLID,
                universityData: []
            }, () => this.getUniversity())
        }
    }
    handleOnScroll = () => {
        if (this.scoRef) {
            const contentScrollTop = this.scoRef.current.scrollTop; //滚动条距离顶部
            const clientHeight = this.scoRef.current.clientHeight; //可视区域
            const scrollHeight = this.scoRef.current.scrollHeight; //滚动条内容的总高度
            if (parseInt(contentScrollTop + clientHeight) >= parseInt(scrollHeight)-1) {
                this.setState({ pageIndex: this.state.pageIndex + 1 }, () => {
                    const { keyword, size, pageIndex, province, type, tag } = this.state
                    const params = {
                        province: province,
                        tag: tag,
                        keyword: keyword,
                        type: type,
                        index: pageIndex,
                        size: size
                    }
                    Toast.show({
                        icon: 'loading',
                        content: '数据加载中',
                        duration: 0,
                        maskClickable: false
                    })
                    axiosPost(0, params, 'University', (res) => {
                        Toast.clear()
                        if (res.code === 0) {
                            this.setState({
                                universityData: [...this.state.universityData, ...res.data.list],
                            })
                            return;
                        }
                        Toast.show('数据加载完毕');
                        return;
                    })
                    return;
                })
            }
        }
    }
    render() {
        const { universityData, contentHeight, contentWidth, isShow, showData, provinceTitle, typeTitle, tagTitle } = this.state
        return (
            <div style={{ overflow: 'hidden' }}>
                <div className={styles.header}>
                    <div className={styles.gobackBox} onClick={this.goback}>
                        <img src={gobackImg} alt='' />
                    </div>
                    <span>院校查询</span>
                </div>
                <div style={{ padding: 0, height: window.innerHeight - 50 + 'px', marginTop: '50px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                    <div className={styles.queryTerms} style={{ marginTop: '10px' }}>
                        <ul className={styles.conditionCate}>
                            <li onClick={() => this.conditionCateClick('1')}>
                                <span>{provinceTitle}</span>
                                <img src={dropdownImg} alt='' />
                            </li>

                            <li onClick={() => this.conditionCateClick('2')}>
                                <span>{typeTitle}</span>
                                <img src={dropdownImg} alt='' />
                            </li>
                            <li onClick={() => this.conditionCateClick('3')}>
                                <span>{tagTitle}</span>
                                <img src={dropdownImg} alt='' />
                            </li>
                        </ul>
                        <div className={styles.spring} style={{ height: isShow ? contentHeight : 0 }} onClick={this.springClick}>
                            <div className={styles.queryList}>
                                <ul>
                                    {showData.map(item => <li key={item.CodeID} onClick={() => this.queryListItemClick(item)}>
                                        {item.CodeName}
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ padding: 0, height: window.innerHeight - 150 + 'px', overflow: 'auto' }}
                        ref={this.scoRef}
                        onScrollCapture={() => { this.handleOnScroll() }}
                    >
                        <div className={styles.schoolList}>
                            <ul>
                                {universityData ? universityData.map(item =>
                                    <li onClick={() => this.schoolInfo(item)} key={item.key}>
                                        <div className={styles.allClude}>
                                            <div className={styles.flexLeft}>
                                                <div className={styles.logoBox}>
                                                    <img src={item.logo} alt='' />
                                                </div>
                                                <div className={styles.schoolInfo} style={{ width: contentWidth - 160 }}>
                                                    <div className={styles.titleAndRank}>
                                                        <span style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>{item.name}</span>
                                                        <div className={styles.schoolRank}>
                                                            <img src={rankImg} alt='' />
                                                            <span>{item.rank}</span>
                                                        </div>
                                                    </div>
                                                    <div className={styles.schoolTags}>
                                                        {item.tagsText.map(child => <span key={child}>{child}</span>)}
                                                    </div>
                                                    <div className={styles.schoolCateLocal}>
                                                        <span><img src={categoryImg} alt='' />{item.categoryText}</span>
                                                        <span><img src={locationImg} alt='' />{item.provinceText + item.cityText}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ) : ''}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}