import React, { Component } from 'react';
import { Dialog, Toast } from 'antd-mobile'
import { axiosPost } from '../../Utils/RequestUtil'
import styles from './index.module.css'

import penImg from '../../assets/images/homeImg/pen.png'
import editImg from '../../assets/images/homeImg/edit.png'
import provinceImg from '../../assets/images/homeImg/province.png'
import rightImg from '../../assets/images/homeImg/icon_right.png'
import wklkImg from '../../assets/images/homeImg/wklk.png'
import goback from '../../assets/images/collegeInquiryImg/goback.png'

export default class UserSetting extends Component {
    constructor() {
        super()
        this.scoreRef = React.createRef()
        this.state = {
            provinceData: [],
            wklkData: [],
            provinceKey: '',
            wklkKey: '',
            provinceTitle: '',
            wklkTitle: '',
            contentHeight: 0,
            activeKey: '',
            score: 0
        }
    }

    componentDidMount() {
        this.getProvince()
        this.getWklkData()
        this.getUser()
        this.setState({
            contentHeight: window.innerHeight
        })
        const score = sessionStorage.getItem('score')
        const rank = sessionStorage.getItem('rank')
        const provinceKey = sessionStorage.getItem('provinceKey')
        const wklkKey = sessionStorage.getItem('wklkKey')
        const provinceTitle = sessionStorage.getItem('provinceTitle')
        const wklkTitle = sessionStorage.getItem('wklkTitle')
        if (score) {
            this.setState({
                score: score
            })
        }
        if (rank) {
            this.setState({
                rank: rank
            })
        }
        if (provinceKey) {
            this.setState({
                provinceKey: provinceKey
            })
        }
        if (wklkKey) {
            this.setState({
                wklkKey: wklkKey
            })
        }
        if (provinceTitle) {
            this.setState({
                provinceTitle: provinceTitle
            })
        }
        if (wklkTitle) {
            this.setState({
                wklkTitle: wklkTitle
            })
        }

    }

    getUser = () => {
        const openId = sessionStorage.getItem('openId')
        const params = {
            user: openId
        }
        axiosPost(9, params, 'University', (res) => {
            if (res.code === 0) {
                const { province, provinceText, score, type, typeText, rank } = res.data.user
                sessionStorage.setItem('score', score)
                sessionStorage.setItem('rank', rank)
                sessionStorage.setItem('provinceKey', province)
                sessionStorage.setItem('provinceTitle', provinceText)
                sessionStorage.setItem('wklkKey', type)
                sessionStorage.setItem('wklkTitle', typeText)
                this.setState({
                    score: score,
                    rank: rank,
                    provinceTitle: provinceText,
                    wklkTitle: typeText,
                    provinceKey: province,
                    wklkKey: type
                })
            } else {
                // sessionStorage.setItem('score', 0)
                // sessionStorage.setItem('provinceKey', '')
                // sessionStorage.setItem('provinceTitle', '')
                // sessionStorage.setItem('wklkKey', '')
                // sessionStorage.setItem('wklkTitle', '')
                this.setState({
                    score: 0,
                    rank: -1,
                    provinceTitle: '',
                    wklkTitle: '',
                    provinceKey: '',
                    wklkKey: ''
                })
            }

        })
    }

    getProvince() {
        const params = {
            flag: 'U-XZQH'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.code === 0) {
                let newProvinceData = res.data.list.filter((item) => item.CodeALLID !== '')
                this.setState({
                    provinceData: newProvinceData
                })
            }
        })
    }


    getWklkData = () => {
        const params = {
            flag: 'U-LKWK'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.code === 0) {
                this.setState({
                    wklkData: res.data.list
                })
            }
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    bodyClick = () => {
        this.setState({
            activeKey: '',
        })
    }

    showAlert = () => {
    }

    chooseClick = (e, decide) => {
        e.stopPropagation()
        const { activeKey } = this.state
        if (activeKey !== decide) {
            this.setState({
                activeKey: decide
            })
        } else {
            this.setState({
                activeKey: ''
            })
        }
    }

    listItemClick(e, item, decide) {
        e.stopPropagation()
        if (decide === 'province') {
            this.setState({
                activeKey: '',
                provinceKey: item.CodeALLID,
                provinceTitle: item.CodeAllName
            })
        } else {
            this.setState({
                activeKey: '',
                wklkKey: item.CodeALLID,
                wklkTitle: item.CodeAllName
            })
        }
    }
    editScoreClick = () => {
        this.textInput.focus();
    }
    scoreChange = (e) => {
        this.setState({
            score: e.target.value
        })
    }
    editRankClick = () => {
        this.rankInput.focus();
    }
    rankChange = (e) => {
        this.setState({
            rank: e.target.value
        })
    }

    submitUserInfo = async () => {
        const { provinceKey, wklkKey, score, provinceTitle, wklkTitle, rank } = this.state
        const openId = sessionStorage.getItem('openId')
        if (score === '' || score === 0) {
            Toast.show({
                content: '没有填写分数'
            })
            return false
        }
        if (rank === '' || rank === 0 || rank === -1) {
            Toast.show({
                content: '没有填写位次'
            })
            return false
        }
        if (provinceKey === '') {
            Toast.show({
                content: '没有选择省份'
            })
            return false
        } else if (wklkKey === '') {
            Toast.show({
                content: '没有选择文理科'
            })
            return false
        }
        const params = {
            user: openId,
            province: provinceKey,
            type: wklkKey,
            score: score,
            rank: rank
        }
        const result = await Dialog.confirm({
            content: '高考成绩提交后不允许修改，确认提交吗？',
        })
        if (result) {
            axiosPost(8, params, 'University', (res) => {
                if (res.code === 0) {
                    sessionStorage.setItem('score', score)
                    sessionStorage.setItem('rank', rank)
                    sessionStorage.setItem('provinceKey', provinceKey)
                    sessionStorage.setItem('provinceTitle', provinceTitle)
                    sessionStorage.setItem('wklkKey', wklkKey)
                    sessionStorage.setItem('wklkTitle', wklkTitle)
                    this.goback();
                }
                else{
                    Toast.show({
                        content: res.message
                    })
                }
            })
        }
    }

    render() {
        const { provinceData, wklkData, contentHeight, score, activeKey, provinceTitle, wklkTitle, rank } = this.state
        return (
            <div className={styles.body} onClick={this.bodyClick}>
                <div className={styles.headerBgc}>
                    <div className={styles.gobackImgBox} onClick={this.goback}>
                        <img src={goback} alt='' />
                    </div>
                    <span>设置</span>
                </div>
                <div className={styles.score} onClick={this.goSetting}>
                    <div className={styles.scoreLeft}>
                        <div className={styles.scoreImgBox}>
                            <img src={penImg} alt='' />
                        </div>
                        <div className={styles.scoreAndLocal}>
                            <h2>分数</h2>
                            <input value={score === '' || score === 0 ? '' : score} onChange={e => this.scoreChange(e)} type='number' ref={(input) => { this.textInput = input; }} />
                        </div>
                    </div>
                    <div onClick={this.editScoreClick}>
                        <div className={styles.editScore}>
                            <img src={editImg} alt='' />
                            <span>修改分数</span>
                        </div>
                    </div>
                </div>
                <div className={styles.score} onClick={this.goSetting}>
                    <div className={styles.scoreLeft}>
                        <div className={styles.scoreImgBox}>
                            <img src={penImg} alt='' />
                        </div>
                        <div className={styles.scoreAndLocal}>
                            <h2>位次</h2>
                            <input value={rank === '' || rank === -1 ? '' : rank} onChange={e => this.rankChange(e)} type='number' ref={(input) => { this.rankInput = input; }} />
                        </div>
                    </div>
                    <div onClick={this.editRankClick}>
                        <div className={styles.editScore}>
                            <img src={editImg} alt='' />
                            <span>修改位次</span>
                        </div>
                    </div>
                </div>
                <div className={styles.chooseBox} onClick={(e) => this.chooseClick(e, 'province')}>
                    <div className={styles.imgBox}>
                        <img src={provinceImg} alt='' />
                    </div>
                    <span>{provinceTitle === '' ? '省份' : provinceTitle}</span>
                    <div className={styles.rightIconBox}>
                        <img src={rightImg} alt='' />
                    </div>
                    <div className={styles.dataListBox} style={{ height: activeKey === 'province' ? contentHeight - 302 : 0 }}>
                        <ul>
                            {provinceData.map(item => <li key={item.CodeALLID} onClick={(e) => this.listItemClick(e, item, 'province')}>
                                {item.CodeAllName}
                            </li>)}
                        </ul>
                    </div>
                </div>
                <div className={styles.chooseBox} onClick={(e) => this.chooseClick(e, 'wklk')}>
                    <div className={styles.imgBox}>
                        <img src={wklkImg} alt='' />
                    </div>
                    <span>{wklkTitle === '' ? '文理科' : wklkTitle}</span>
                    <div className={styles.rightIconBox}>
                        <img src={rightImg} alt='' />
                    </div>
                    <div className={styles.dataListBox} style={{ height: activeKey === 'wklk' ? '82px' : 0 }}>
                        <ul>
                            {wklkData.map(item => <li key={item.CodeALLID} onClick={(e) => this.listItemClick(e, item, 'wklk')}>
                                {item.CodeAllName}
                            </li>)}
                        </ul>
                    </div>
                </div>
                <div className={styles.submitBox} onClick={this.submitUserInfo}>
                    <span>提交</span>
                </div>
            </div>
        )
    }
}