import React, { Component } from 'react';
import { axiosPost } from '../../Utils/RequestUtil'
import { Toast } from 'antd-mobile'
import styles from './index.module.css'

import MajorSummary from '../MajorSummary/index.js'
import MajorHasSchool from '../MajorHasSchool/index.js'

import gobackImg from '../../assets/images/collegeInquiryImg/goback.png'


export default class MajorDetail extends Component {
    constructor() {
        super()
        this.state = {
            majorInfo: {},
            activeKey: '',
            majorName: '',
            majorId:''
        }
    }

    componentDidMount() {
        const { majorId, majorName } = this.props.match.params
        this.setState({
            majorId:majorId,
            majorName: majorName
        })
        this.getMajorDetail(majorId)
    }

    getMajorDetail = (key) => {
        const params = {
            key: key
        }
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(5, params, 'University', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.setState({
                    majorInfo: res.data.speciality,
                    activeKey: 'jj'
                })
            }
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    tabsClick = (decide) => {
        this.setState({
            activeKey: decide
        })
    }

    render() {
        const { majorInfo, activeKey, majorName,majorId } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.father}>
                    <div className={styles.headerBgc}>
                        <div className={styles.gobackImgBox} onClick={this.goback}>
                            <img src={gobackImg} alt='' />
                        </div>
                        <span>{majorName}</span>
                    </div>
                    <div className={styles.tabsBox}>
                        <ul>
                            <li onClick={() => this.tabsClick('jj')}>
                                <span>简介</span>
                                <div style={{ display: activeKey === 'jj' ? 'block' : 'none' }}></div>
                            </li>
                            <li onClick={() => this.tabsClick('ksyx')}>
                                <span>开设院校</span>
                                <div style={{ display: activeKey === 'ksyx' ? 'block' : 'none' }}></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.childPages}>
                    {
                        activeKey === 'jj' ? <MajorSummary majorInfo={majorInfo}></MajorSummary> :
                            activeKey === 'ksyx' ? <MajorHasSchool history={this.props.history} majorKey={majorId}></MajorHasSchool> : ''
                    }
                </div>
            </div>
        )
    }
}