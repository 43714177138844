import React, { Component } from 'react';
import { axiosPost } from '../../Utils/RequestUtil'
import { Toast } from 'antd-mobile'
import styles from './index.module.css'

import SearchFastPage from '../SearchFastPage/index'
import SearchData from '../SearchData/index'

import goback from '../../assets/images/collegeInquiryImg/goback.png'
import searchImg from '../../assets/images/homeImg/search.png'
import pageback from '../../assets/images/homeImg/pageback.png'
export default class Search extends Component {
    constructor() {
        super()
        this.state = {
            searchKey: '',
            searchData: {},
            dataLoad: '1'
        }
    }


    fastSearch = (result, keyword) => {
        this.setState({
            searchKey: keyword
        })
        const params = {
            keyword: keyword
        }
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        this.setState({
            dataLoad: '3'
        })
        axiosPost(7, params, 'University', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.setState({
                    searchData: res.data,
                    dataLoad: '2'
                })
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未找到相关数据',
                })
                this.setState({
                    dataLoad: '2'
                })
            }
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }
    search = (e) => {
        const searchKey = e.target.value
        this.setState({
            searchKey: searchKey
        })
    }

    searchClick = () => {
        const { searchKey } = this.state
        if (searchKey === '') {
            Toast.show({
                icon: 'fail',
                content: '搜索关键词不能为空',
            })
            this.setState({
                dataLoad: '1'
            })
        } else {
            const params = {
                keyword: searchKey
            }
            this.setState({
                dataLoad: '3'
            })
            Toast.show({
                icon: 'loading',
                content: '数据加载中',
                duration: 0,
                maskClickable: false
            })
            axiosPost(7, params, 'University', (res) => {
                Toast.clear()
                if (res.code === 0) {
                    this.setState({
                        searchData: res.data,
                        dataLoad: '2'
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: '未找到相关数据',
                    })
                    this.setState({
                        dataLoad: '2'
                    })
                }
            })
        }
    }

    render() {
        const { searchData, dataLoad, searchKey } = this.state
        return (
            <div style={{overflow:'hidden'}}>
                <div className={styles.header}>
                    <div className={styles.gobackBox} onClick={this.goback}>
                        <img src={goback} alt='' />
                    </div>
                    <div className={styles.inputBox}>
                        <img src={searchImg} alt='' />
                        <input placeholder='输入关键字进行搜索' value={searchKey} onChange={(e) => this.search(e)} />
                    </div>
                    <span onClick={() => this.searchClick()}>搜索</span>
                </div>
                <div style={{height:window.innerHeight-50+'px',marginTop:'50px',backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0', overflow: 'auto',padding:'10px'}}>
                    {
                        dataLoad === '2' ? <SearchData searchData={searchData} history={this.props.history}></SearchData> :
                            dataLoad === '1' ?
                                <SearchFastPage parent={this} history={this.props.history}></SearchFastPage> : ''
                    }
                </div>
            </div>
        )
    }
}