import React, { Component } from 'react';
import styles from './index.module.css'

import openImg from '../../assets/images/schoolDetailImg/open.png'
import closeImg from '../../assets/images/schoolDetailImg/close.png'

export default class MajorSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            majorInfo: this.props.majorInfo,
            isOpen: false
        }
    }

    componentDidMount() {

    }

    openClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const { majorInfo, isOpen } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.summary}>
                    <h2>专业概述</h2>
                    <div className={isOpen ? styles.introductionOpen : styles.introduction}>
                        <p>
                            {majorInfo.introduction===''?<span>暂无概述</span>:majorInfo.introduction}
                        </p>
                        <div className={styles.detailOpen} onClick={this.openClick}>
                            <span>{isOpen ? '收起' : '展开'}</span>
                            <img src={isOpen ? closeImg : openImg} alt='' />
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <div className={styles.majorIntroBox}>
                        <div className={styles.majorIntro}>
                            <h2>专业情况介绍</h2>
                            <div className={styles.majorIntroDetail}>
                                <span>培养目标 :</span>
                                <p>
                                    {majorInfo.objective}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>必修课程 :</span>
                                <p>
                                    {majorInfo.compulsory}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>辅修课程 :</span>
                                <p>
                                    {majorInfo.elective}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>知识技能 :</span>
                                <p>
                                    {majorInfo.knowledgeSkill}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>必备证书 :</span>
                                <p>
                                    {majorInfo.certificate}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>发展前景 :</span>
                                <p>
                                    {majorInfo.develop}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>薪资待遇 :</span>
                                <p>
                                    {majorInfo.salary}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>就业前景 :</span>
                                <p>
                                    {majorInfo.prospects}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>能力要求 :</span>
                                <p>
                                    {majorInfo.competencies}
                                </p>
                            </div>
                            <div className={styles.majorIntroDetail}>
                                <span>用人要求 :</span>
                                <p>
                                    {majorInfo.employment}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}