import React, { Component } from 'react';
import { Toast } from 'antd-mobile';
import styles from './index.module.css'
import { axiosPost } from '../../Utils/RequestUtil'

import Introduction from '../Introduction/index.js'
import Major from '../Major/index.js'
import EnrollData from '../EnrollData/index.js'
import EnrollmentPlan from '../EnrollmentPlan/index.js'

import gobackImg from '../../assets/images/collegeInquiryImg/goback.png'
import schoolLogo from '../../assets/images/collegeInquiryImg/schoolLogo.jpg'
import locationImg from '../../assets/images/collegeInquiryImg/location.png'
import rankImg from '../../assets/images/collegeInquiryImg/rank.png'
import categoryImg from '../../assets/images/collegeInquiryImg/category.png'
import underlineImg from '../../assets/images/schoolDetailImg/underline.png'
import pageback from '../../assets/images/homeImg/pageback.png'

const tabsCateData = [
    {
        key: 'introduction',
        title: '简介',
    },
    {
        key: 'major',
        title: '专业',
    },
    {
        key: 'enrollData',
        title: '录取数据',
    },
    {
        key: 'enrollmentPlan',
        title: '招生计划',
    }
]

export default class SchoolInfo extends Component {
    constructor(props) {
        super(props)
        let batch = '';
        if (this.props.match.params.batch && this.props.match.params.batch !== '1') {
            batch = this.props.match.params.batch
        }
        this.state = {
            flag: '',
            universityKey: this.props.match.params.key,
            universityName: this.props.match.params.name,
            universityBatch: batch,
            universityInfo: {},
            admitBatch: [],
            planBatch: [],
            enrollData: [],
            enrollPlanData: [],
            admitBatchTitle: '',
            planBatchTitle: '',
            plansTotal: 0,
            contentHeight: 0,
            contentWidth: 0,
            isShow: false,
            batchData: []
        }
    }

    componentDidMount() {
        const contentHeight = window.screen.availHeight
        const contentWidth = window.screen.availWidth
        this.setState({
            contentHeight: contentHeight,
            contentWidth: contentWidth
        })
        this.getUniversityInfo()
        this.getBatch()
    }

    getUniversityInfo = () => {
        const { universityKey } = this.state
        const provinceKey = sessionStorage.getItem('provinceKey')
        const type = sessionStorage.getItem('wklkKey')
        const params = {
            key: universityKey,
            province: provinceKey,
            type: type
        }
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(1, params, 'University', (res) => {
            Toast.clear()
            this.setState({
                universityInfo: res.data.university,
                flag: 'introduction'
            })
        })
    }

    getBatch = () => {
        const provinceKey = sessionStorage.getItem('provinceKey')
        const type = sessionStorage.getItem('wklkKey')
        const { universityKey } = this.state
        const params = {
            key: universityKey,
            province: provinceKey,
            type: type
        }
        axiosPost(11, params, 'University', (res) => {
            if (res.code === 0) {
                if (res.data.admitBatch.length > 0) {
                    this.setState({
                        admitBatch: res.data.admitBatch
                    })
                }
                if (res.data.planBatch.length > 0) {
                    this.setState({
                        planBatch: res.data.planBatch
                    })
                }
            }
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    tabsChange = (e) => {
        if (e === 'enrollData') {
            this.setState({
                flag: e
            })
            this.getEnrollData()
        } else if (e === 'enrollmentPlan') {
            this.setState({
                flag: e
            })
            this.getEnrollPlan()
        } else {
            this.setState({
                flag: e,

            })
        }
    }

    getEnrollData = () => {
        const provinceKey = sessionStorage.getItem('provinceKey')
        const type = sessionStorage.getItem('wklkKey')
        if (provinceKey === null || type === null) {
            this.showAlert()
            this.setState({
                enrollData: []
            })
        } else if (this.state.admitBatch.length === 0) {
            this.setState({
                enrollData: []
            })
        } else {
            if (this.state.universityBatch && this.state.universityBatch !== '') {
                this.goGetEnrollData(this.state.universityBatch)
            }
            else {
                this.goGetEnrollData(this.state.admitBatch[0])
            }
        }
    }
    goGetEnrollData = (batch) => {
        const { universityKey } = this.state
        const provinceKey = sessionStorage.getItem('provinceKey')
        const type = sessionStorage.getItem('wklkKey')
        const params = {
            key: universityKey,
            batch: batch,
            province: provinceKey,
            type: type
        }
        this.setState({
            flag: ''
        })
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(3, params, 'University', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.setState({
                    enrollData: res.data.admits,
                    flag: 'enrollData',
                    admitBatchTitle: batch
                })
            }
        })
    }

    getEnrollPlan = () => {
        const provinceKey = sessionStorage.getItem('provinceKey')
        const type = sessionStorage.getItem('wklkKey')
        if (provinceKey === null || type === null) {
            this.showAlert()
            this.setState({
                enrollPlanData: []
            })
        } else if (this.state.planBatch.length === 0) {
            this.setState({
                enrollPlanData: []
            })
        } else {
            if (this.state.universityBatch && this.state.universityBatch !== '') {
                this.goGetEnrollPlan(this.state.universityBatch)
            }
            else {
                this.goGetEnrollPlan(this.state.planBatch[0])
            }
        }
    }
    goGetEnrollPlan = (batch) => {
        const { universityKey } = this.state
        const provinceKey = sessionStorage.getItem('provinceKey')
        const type = sessionStorage.getItem('wklkKey')
        const params = {
            key: universityKey,
            batch: batch,
            province: provinceKey,
            type: type
        }
        this.setState({
            flag: ''
        })
        this.setState({
            flag: ''
        })
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(4, params, 'University', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.setState({
                    enrollPlanData: res.data.plans,
                    plansTotal: res.data.total,
                    flag: 'enrollmentPlan',
                    planBatchTitle: batch
                })
            }
        })
    }

    showAlert = () => {

    }

    showSpring = (e) => {
        this.setState({
            isShow: true,
            batchData: e === 'plans' ? this.state.planBatch : this.state.admitBatch
        })
    }

    closeSpring = () => {
        this.setState({
            isShow: false
        })
    }

    batchClick = (e, item) => {
        e.stopPropagation()
        this.setState({
            isShow: false
        })
        if (this.state.flag === 'enrollData') {
            this.goGetEnrollData(item)
        }
        if (this.state.flag === 'enrollmentPlan') {
            this.goGetEnrollPlan(item)
        }
    }

    render() {
        const { flag, isShow, universityName, universityInfo, batchData, contentWidth, universityKey, enrollData, admitBatchTitle, enrollPlanData, planBatchTitle, plansTotal, contentHeight } = this.state
        return (
            <div style={{ overflow: 'hidden' }}>
                <div className={styles.header}>
                    <div className={styles.gobackBox} onClick={this.goback}>
                        <img src={gobackImg} alt='' />
                    </div>
                    <span>{universityName}</span>
                </div>
                <div style={{ padding: 0, height: window.innerHeight - 50 + 'px', marginTop: '50px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0', overflow: 'auto' }}>
                    <div className={styles.schoolTitle} style={{ marginTop: '10px' }}>
                        <div className={styles.allClude}>
                            <div className={styles.flexLeft}>
                                <div className={styles.logoBox}>
                                    <img src={universityInfo.logo === '' ? schoolLogo : universityInfo.logo} alt='' />
                                </div>
                                <div className={styles.schoolInfo} style={{ width: contentWidth - 160 }}>
                                    <div className={styles.titleAndRank}>
                                        <span style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>{universityInfo.name}</span>
                                        <div className={styles.schoolRank}>
                                            <img src={rankImg} alt='' />
                                            <span>{universityInfo.rank}</span>
                                        </div>
                                    </div>
                                    <div className={styles.schoolTags}>
                                        {universityInfo.tagsText ? universityInfo.tagsText.map(item => <span key={item}>{item}</span>) : ''}
                                    </div>
                                    <div className={styles.schoolCateLocal}>
                                        <span><img src={categoryImg} alt='' />{universityInfo.categoryText}</span>
                                        <span><img src={locationImg} alt='' />{universityInfo.provinceText + universityInfo.cityText}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tabsCate}>
                        <ul>
                            {
                                tabsCateData.map(item =>
                                    <li onClick={() => { this.tabsChange(item.key) }} key={item.key}>
                                        <span style={{ color: flag === item.key ? '#333' : '#999' }}>{item.title}</span>
                                        {flag === item.key ?
                                            <img src={underlineImg} alt='' />
                                            : ''}
                                    </li>)
                            }
                        </ul>
                    </div>
                    <div className={styles.detailContent}>
                        {flag === 'introduction' ? <Introduction history={this.props.history} universityInfo={universityInfo}></Introduction> :
                            flag === 'major' ? <Major history={this.props.history} universityKey={universityKey}></Major> :
                                flag === 'enrollData' ? <EnrollData enrollData={enrollData} admitBatchTitle={admitBatchTitle} showSpring={this.showSpring}></EnrollData> :
                                    flag === 'enrollmentPlan' ?
                                        <EnrollmentPlan enrollPlanData={enrollPlanData} planBatchTitle={planBatchTitle} total={plansTotal} showSpring={this.showSpring}></EnrollmentPlan> : ''
                        }
                    </div>
                    <div className={styles.spring} style={{ height: contentHeight, display: isShow ? 'block' : 'none' }} onClick={this.closeSpring}>
                        <div className={styles.admitBatchBox} onClick={this.closeSpring}>
                            <ul onClick={this.closeSpring}>
                                {batchData.map((item, index) => <li key={index} onClick={(e) => this.batchClick(e, item)}>{item}</li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}