import React, { Component } from 'react';
import { axiosPost, axiosPostWithToken } from '../../Utils/RequestUtil'
import styles from './index.module.css'
import moment from 'moment';
import penImg from '../../assets/images/homeImg/pen.png'
import editImg from '../../assets/images/homeImg/edit.png'
import searchImg from '../../assets/images/homeImg/search.png'
import znppback from '../../assets/images/homeImg/智能匹配.png';
import cjfxback from '../../assets/images/homeImg/成绩分析.png';
import { Button, Toast, Popup } from 'antd-mobile';

export default class ChooseSchool extends Component {
    constructor() {
        super()
        this.state = {
            wxOpenId: 'aaa',
            newsData: [],
            score: 0,
            province: '',
            type: '',
            provinceText: '',
            typeText: '',
            contentHeight: 0,
            endTime: '',
            rank: '',
            agreementTitle: '',
            agreementSign: true,
            agreementAddress: '',
        }
    }

    componentDidMount() {
        let openId = this.queryParam('openId')
        sessionStorage.setItem('openId', openId)
        if (openId === null) {
            Toast.show({ icon: 'fail', content: '授权失败，请重新进入' });
            return;
        }
        openId = sessionStorage.getItem('openId')
        this.setState({ wxOpenId: openId })
        try {
            this.initJssdk();
        } catch (e) {
            if (this.state.wxOpenId === 'o2wbbwBiQBDl9oWQwPbLoGhxPcSk') {
                alert(e);
                alert(JSON.stringfiy(e))
            }
        }
        this.getVip();
        this.getNews()
        this.getUserSetting(openId)
        this.getAgreement(openId);
        const contentHeight = window.innerHeight - 370
        this.setState({
            contentHeight: contentHeight
        })
    }
    initJssdk = () => {
        const body = {
            url: window.location.href
        }
        axiosPostWithToken(1, '', body, 'Weixin', (res) => {
            Toast.clear();
            if (res.code === 0) {
                this.initJssdkConfig(res.data);
                return;
            }
            return;
        })
    }
    initJssdkConfig = (options) => {
        try {
            options.debug = false;
            options.jsApiList = ['updateAppMessageShareData', 'updateTimelineShareData', 'hideMenuItems', 'chooseWXPay'];
            let wx = window.wx;
            wx.config(options);
            wx.error((res) => {
                if (this.state.wxOpenId === 'o2wbbwBiQBDl9oWQwPbLoGhxPcSk') {
                    console.log(res)
                    alert(JSON.stringfiy(res))
                }
            });
            wx.ready(() => {
                wx.hideMenuItems({
                    menuList: ['menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:favorite', 'menuItem:share:facebook', 'menuItem:share:QZone', 'menuItem:editTag', 'menuItem:delete', 'menuItem:copyUrl', 'menuItem:originPage', 'menuItem:readMode', 'menuItem:openWithQQBrowser', 'menuItem:openWithSafari', 'menuItem:share:email', 'menuItem:share:brand']
                });
                wx.updateAppMessageShareData({
                    title: '高考志愿助手',
                    desc: '志愿分析报告、成绩预测',
                    link: 'https://api.jhxhzn.com/register/wxUniversityJump.html',
                    imgUrl: 'https://api.jhxhzn.com/web/hyx.png',
                    success: function () { }
                });
                wx.updateTimelineShareData({
                    title: '高考志愿助手',
                    link: 'https://api.jhxhzn.com/register/wxUniversityJump.html',
                    imgUrl: 'https://api.jhxhzn.com/web/hyx.png',
                    success: function () { }
                });
            });
        }
        catch (e) {
            if (this.state.wxOpenId === 'o2wbbwBiQBDl9oWQwPbLoGhxPcSk') {
                alert(e);
                alert(JSON.stringfiy(e))
            }
        }
    }
    getVip = () => {
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        const body = {
            RelKey: sessionStorage.getItem('openId'),
            UserKey: sessionStorage.getItem('openId')
        }
        axiosPost(18, body, 'University', (res) => {
            Toast.clear();
            if (res.code === 0) {
                this.setState({ endTime: res.data.EndTime });
                return;
            }
            return;
        })
    }
    getNews() {
        const params = {
            key: '',
            index: 0,
            size: 10
        }
        axiosPost(6, params, 'University', (res) => {
            if (res.code === 0) {
                this.setState({
                    newsData: res.data.list
                })
            }
        })
    }

    getUserSetting(openId) {
        const params = {
            user: openId
        }
        axiosPost(9, params, 'University', (res) => {
            if (res.code === 1) {
                sessionStorage.clear()
                sessionStorage.setItem('openId', openId)
                Toast.clear();
                Toast.show('尚未设置分数');
            } else {
                const { province, provinceText, score, type, typeText, rank } = res.data.user
                sessionStorage.setItem('score', score)
                sessionStorage.setItem('provinceKey', province)
                sessionStorage.setItem('provinceTitle', provinceText)
                sessionStorage.setItem('wklkKey', type)
                sessionStorage.setItem('wklkTitle', typeText)
                sessionStorage.setItem('rank', rank)
                this.setState({
                    score: score,
                    provinceText: provinceText,
                    typeText: typeText,
                    province: province,
                    type: type,
                    rank: rank
                })
            }
        })
    }
    getAgreement = (openId) => {
        const params = {
            UserKey: openId
        }
        axiosPost(24, params, 'University', (res) => {
            if (res.code === 0) {
                this.setState({ agreementAddress: res.data.list[0].Address, agreementSign: false, agreementTitle: res.data.list[0].Title });
                return;
            }
            this.setState({ agreementAddress: '', agreementSign: true, agreementTitle: '' })
            return;
        })
    }
    collegeInquiry = () => {
        sessionStorage.removeItem('isBack')
        sessionStorage.removeItem('chooseProTil')
        sessionStorage.removeItem('chooseProId')
        sessionStorage.removeItem('chooseTypeTil')
        sessionStorage.removeItem('chooseTypeId')
        sessionStorage.removeItem('chooseTagTil')
        sessionStorage.removeItem('chooseTagId')
        this.props.history.push('/collegeInquiry')
    }
    allMajorIntro = () => {
        this.props.history.push('/allMajorIntro')
    }

    goSearch = () => {
        this.props.history.push('/search')
    }

    goSetting = () => {
        this.props.history.push('/userSetting')
    }

    queryParam = (name) => {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }

    goNews = (item) => {
        this.props.history.push({ pathname: '/news', query: { newsKey: item.url, newsTitle: item.title } })
    }

    goNewsList = () => {
        this.props.history.push('/newsList')
    }

    searchWithScore = () => {
        const { province, type, score } = this.state
        if (province !== '' && type !== '' && score !== 0) {
            this.props.history.push('/scoreSchool')
        } else {
            Toast.clear();
            Toast.show('请先设置分数');
        }

    }

    onAchievementClick = () => {
        this.props.history.push('/achievement')
    }
    onOrderClick = () => {
        this.props.history.push(`/achievementOrder`, null);
    }
    onAgreementClick = () => {
        let openId = sessionStorage.getItem('openId');
        const params = {
            UserKey: openId
        }
        axiosPost(25, params, 'University', (res) => {
            if (res.code === 0) {
                this.setState({ agreementAddress: '', agreementSign: true, agreementTitle: '' });
                return;
            }
            Toast.show(res.messsage);
            return
        })
    }
    render() {
        const { newsData, score, provinceText, typeText, contentHeight, rank } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.header}>
                    <div className={styles.searchBox} style={{ backgroundColor: '#6cacff' }} onClick={this.goSearch}>
                        <img src={searchImg} alt='' />
                        <input style={{ backgroundColor: '#6cacff', color: '#fff' }} placeholder='请输入关键字搜索' />
                    </div>
                </div>
                <div className={styles.score} onClick={this.goSetting}>
                    <div className={styles.scoreLeft}>
                        <div className={styles.scoreImgBox}>
                            <img src={penImg} alt='' />
                        </div>
                        <div className={styles.scoreAndLocal}>
                            <h2>分数<i>{score}</i></h2>
                            <h2>位次<i>{rank}</i></h2>
                            <span>{provinceText}.{typeText}</span>
                        </div>
                    </div>
                    <div>
                        <div className={styles.editScore}>
                            <img src={editImg} alt='' />
                            <span>修改分数</span>
                        </div>
                    </div>
                </div>
                <div style={{ height: '30px', marginTop: '10px' }}>
                    {
                        this.state.endTime === ''
                            ? <div style={{ padding: 5, paddingLeft: '15px' }}>
                                <div style={{ float: 'left', fontSize: '14px' }}><div style={{ width: '4px', backgroundColor: '#449bfe', borderRadius: '2px', float: 'left' }}>&nbsp;</div><div style={{ width: '200px', lineHeight: '16px' }}>&nbsp;&nbsp;成为会员享受更多服务</div></div>
                                <div style={{ float: 'right' }}><Button style={{ fontSize: '14px', lineHeight: '9px' }} onClick={() => { this.onOrderClick() }} size='small' color='primary' fill='none'>升级为会员&gt;&gt;</Button></div>
                            </div>
                            : <div style={{ padding: 5, paddingLeft: '15px' }}>
                                <div style={{ float: 'left', fontSize: '14px' }}><div style={{ width: '4px', backgroundColor: '#449bfe', borderRadius: '2px', float: 'left' }}>&nbsp;</div><div style={{ width: '250px', lineHeight: '16px' }}>&nbsp;&nbsp;会员有效期至：{moment(this.state.endTime).format("YYYY年MM月DD日")}</div></div>
                                <div style={{ float: 'right' }}><Button style={{ fontSize: '14px', lineHeight: '9px' }} onClick={() => { this.onOrderClick() }} size='small' color='primary' fill='none'>续订&gt;&gt;</Button></div>
                            </div>
                    }
                </div>
                <div className={styles.cxlb} style={{ marginTop: '10px', clear: 'both' }}>
                    <div className={styles.queryAndAnalysis}>
                        <div className={styles.schoolQuery} style={{ backgroundImage: 'url(' + znppback + ')' }} onClick={this.searchWithScore}>
                            <h2>智能匹配</h2>
                            <span>通过分数智能匹配学校</span>
                        </div>
                        <div className={styles.specialAnalysis} style={{ backgroundImage: 'url(' + cjfxback + ')' }} onClick={this.onAchievementClick}>
                            <h2>成绩分析</h2>
                            <span>录入月考模拟考等成绩</span>
                        </div>
                    </div>
                    <div className={styles.queryAndAnalysis}>
                        <div className={styles.schoolQuery} onClick={this.collegeInquiry}>
                            <h2>院校查询</h2>
                            <span>历史录取数据全掌握</span>
                        </div>
                        <div className={styles.specialAnalysis} onClick={this.allMajorIntro}>
                            <h2>专业介绍</h2>
                            <span>专业就业前景分析</span>
                        </div>
                    </div>
                </div>

                <div className={styles.dynamicBox} style={{ height: contentHeight, overflow: 'auto', clear: 'both' }}>
                    <div className={styles.dynamicTitle}>
                        <span className={styles.title}>高校动态<i>最新</i></span>
                        <span className={styles.more} onClick={this.goNewsList}>查看全部 》</span>
                    </div>
                    {newsData ? newsData.map(item =>
                        <div className={styles.dynamicItem} key={item.key} onClick={() => this.goNews(item)}>
                            <div className={styles.itemTitle}>
                                <span className={styles.schoolName}>{item.universityText}</span>
                                <span className={styles.time}>发布时间: {item.time}</span>
                            </div>
                            <div className={styles.dynamicContent}>
                                <div className={styles.contentInfo}>
                                    <p>{item.title}</p>
                                    <span>{item.desc}</span>
                                </div>
                                <div className={styles.contentImgBox}>
                                    <img src={item.image} alt='' />
                                </div>
                            </div>
                        </div>
                    ) : ''}

                </div>
                <Popup
                    visible={!this.state.agreementSign}
                    bodyStyle={{ height: '400px', padding: '10px' }}
                >
                    <h3 style={{ width: '100%', height: '30px', lineHeight: '30px', textAlign: 'center', fontSize: '16px' }}>{this.state.agreementTitle}</h3>
                    <iframe title='agreement' style={{ border: '0px solid #ccc', width: '100%', height: '300px', marginBottom: '10px' }} src={this.state.agreementAddress} />
                    <Button color='primary' block onClick={() => { this.onAgreementClick() }}>同意协议并使用</Button>
                </Popup>
            </div>
        )
    }
}