import React, { Component } from 'react';
import { NavBar, Form, Input, Button, DatePicker, Radio, Grid, Toast, Picker } from 'antd-mobile';
import { TextOutline, EnvironmentOutline, TransportQRcodeOutline, UserOutline } from 'antd-mobile-icons'
import { axiosPost } from '../../Utils/RequestUtil';
import './index.module.css';
import dayjs from 'dayjs';
import pageback from '../../assets/images/homeImg/pageback.png'
import subjectchs from '../../assets/images/homeImg/subject_chs.png'
import subjectmat from '../../assets/images/homeImg/subject_mat.png'
import subjecteng from '../../assets/images/homeImg/subject_eng.png'
import subjectphy from '../../assets/images/homeImg/subject_phy.png'
import subjectche from '../../assets/images/homeImg/subject_che.png'
import subjectbio from '../../assets/images/homeImg/subject_bio.png'
import subjectpol from '../../assets/images/homeImg/subject_pol.png'
import subjecthis from '../../assets/images/homeImg/subject_his.png'
import subjectgeo from '../../assets/images/homeImg/subject_geo.png'
const height = window.innerHeight;
export default class AchievementAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testDateVis: false,
            provinceData: [[]],
            proVis: false
        };
    }
    formRef = React.createRef();
    componentDidMount() {
        this.getProvince();
    }
    getProvince = () => {
        const params = {
            flag: 'U-XZQH'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.code === 0) {
                let newProvinceData = [];
                res.data.list.forEach(item => {
                    if (item.CodeALLID !== '') {
                        item.label = item.CodeAllName;
                        item.value = item.CodeALLID;
                        newProvinceData.push(item);
                    }
                });
                this.setState({
                    provinceData: newProvinceData
                }, () => {
                    if (this.props.location.state !== undefined && this.props.location.state !== null) {
                        let date = new Date(this.props.location.state.Year.replace('年', '-').replace('月', '-').replace('日', ''));
                        this.formRef.current?.setFieldsValue({
                            name: this.props.location.state.Title,
                            date: date,
                            type: this.props.location.state.Type,
                            chinese: this.props.location.state.Language,
                            math: this.props.location.state.Mathematics,
                            english: this.props.location.state.English,
                            physics: this.props.location.state.Physics,
                            chemistry: this.props.location.state.Chemistry,
                            biology: this.props.location.state.Biology,
                            politics: this.props.location.state.Politics,
                            history: this.props.location.state.History,
                            geography: this.props.location.state.Geography,
                            province: [this.state.provinceData.filter(p => p.value === this.props.location.state.Province)[0].value],
                        })
                    }
                });
            }
        })

    }
    goback = () => {
        this.props.history.go(-1)
    }
    onAddFinish = (values) => {
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        values.date = dayjs(values.date).format('YYYY年MM月DD日');
        values.physics = values.physics === undefined || values.physics === null || values.physics === '' ? '0' : values.physics;
        values.chemistry = values.chemistry === undefined || values.chemistry === null || values.chemistry === '' ? '0' : values.chemistry;
        values.biology = values.biology === undefined || values.biology === null || values.biology === '' ? '0' : values.biology;
        values.politics = values.politics === undefined || values.politics === null || values.politics === '' ? '0' : values.politics;
        values.history = values.history === undefined || values.history === null || values.history === '' ? '0' : values.history;
        values.geography = values.geography === undefined || values.geography === null || values.geography === '' ? '0' : values.geography;
        const body = {
            Id: this.props.location.state !== undefined && this.props.location.state !== null ? this.props.location.state.Id : '',
            RelKey: sessionStorage.getItem('openId'), Title: values.name,
            Year: values.date,
            Province: values.province[0],
            Type: values.type,
            Language: values.chinese,
            Mathematics: values.math,
            English: values.english,
            Physics: values.physics,
            Politics: values.politics,
            Chemistry: values.chemistry,
            History: values.history,
            Geography: values.geography,
            Biology: values.biology
        }
        axiosPost(13, body, 'University', (res) => {
            Toast.clear();
            if (res.code === 0) {
                Toast.show({
                    icon: 'success',
                    content: res.message
                })
                this.goback();
                return;
            }
            Toast.show({
                icon: 'fail',
                content: res.message
            })
            return;
        })
    }
    render() {
        return <>
            <NavBar style={{ backgroundColor: '#449bfe', color: '#fff', height: '50px', lineHeight: '50px', borderColor: '#449bfe' }} onBack={this.goback}>
                {this.props.location.state !== undefined && this.props.location.state !== null ? '修改成绩' : '添加成绩'}
            </NavBar>
            <div style={{ height: height - 50 + 'px',padding:'10px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                <div style={{ borderRadius: '10px', border: '1px solid #fff', padding: '10px', backgroundColor: '#fff' }}>
                    <Form
                        name='form'
                        ref={this.formRef}
                        onFinish={this.onAddFinish}
                        border={false}
                        style={{
                            '--border-inner': '0px solid #ccc',
                            '--border-top': '0px solid #ccc',
                            '--border-bottom': '0px solid #ccc',
                        }}
                    >
                        <div style={{ height: height - 130 + 'px',overflow:'auto' }}>
                            <Grid columns={1}>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='name'
                                        layout='horizontal'
                                        label={<div>
                                            <TextOutline size={24} color='var(--adm-color-primary)' />考试名称
                                        </div>}
                                        rules={[{ required: true, message: '请输入考试名称' }]}
                                    >
                                        <Input placeholder='请输入考试名称' />
                                    </Form.Item>
                                </Grid.Item>
                            </Grid>
                            <Grid columns={1}>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='date'
                                        trigger='onConfirm'
                                        layout='horizontal'
                                        label={<div>
                                            <TransportQRcodeOutline size={24} color='var(--adm-color-primary)' />考试日期
                                        </div>}
                                        rules={[{ required: true, message: '请选择考试日期' }]}
                                        onClick={() => {
                                            this.setState({ testDateVis: true })
                                        }}
                                    >
                                        <DatePicker
                                            visible={this.state.testDateVis}
                                            onClose={() => {
                                                this.setState({ testDateVis: false });
                                            }}
                                        >{value =>
                                            value ? dayjs(value).format('YYYY-MM-DD') : '请选择考试日期'
                                            }
                                        </DatePicker>
                                    </Form.Item>
                                </Grid.Item>
                            </Grid>
                            <Grid columns={1}>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='province'
                                        trigger='onConfirm'
                                        layout='horizontal'
                                        label={<div>
                                            <EnvironmentOutline size={24} color='var(--adm-color-primary)' />考试省份
                                        </div>}
                                        rules={[{ required: true, message: '请输入考试省份' }]}
                                        onClick={() => {
                                            this.setState({ proVis: true })
                                        }}
                                    >
                                        {
                                            this.state.provinceData.length > 0 ? <Picker
                                                visible={this.state.proVis}
                                                columns={[this.state.provinceData]}
                                                onClose={() => {
                                                    this.setState({ proVis: false });
                                                }}
                                            >{value =>
                                                value.length > 0 ? value[0].label : '请选择考试省份'
                                                }
                                            </Picker> : null
                                        }
                                    </Form.Item>
                                </Grid.Item>
                            </Grid>
                            <Grid columns={1}>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='type'
                                        layout='horizontal'
                                        label={<div>
                                            <UserOutline size={24} color='var(--adm-color-primary)' />考生类型
                                        </div>}
                                        rules={[{ required: true, message: '请输入考生类型' }]}
                                    >
                                        <Radio.Group>
                                            <Radio value='01'>理科</Radio>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Radio value='02'>文科</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Grid.Item>
                            </Grid>
                            <Grid columns={3} gap={5}>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='chinese'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjectchs} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;语文</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: true, message: '请填写' }]}
                                    >
                                        <Input placeholder='语文分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='math'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjectmat} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;数学</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: true, message: '请填写' }]}
                                    >
                                        <Input placeholder='数学分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='english'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjecteng} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;外语</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: true, message: '请填写' }]}
                                    >
                                        <Input placeholder='外语分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                            </Grid>
                            <Grid columns={3} gap={5}>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='physics'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjectphy} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;物理</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder='物理分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='chemistry'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjectche} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;化学</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder='化学分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='biology'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjectbio} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;生物</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder='生物分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                            </Grid>
                            <Grid columns={3} gap={5}>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='politics'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjectpol} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;政治</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder='政治分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='history'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjecthis} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;历史</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder='历史分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                                <Grid.Item style={{ border: '1px solid #ddd', marginTop: '5px', borderRadius: '5px', padding: '5px' }}>
                                    <Form.Item
                                        name='geography'
                                        label={<>
                                            <div style={{ height: '30px' }}>
                                                <div style={{ float: 'left' }}><img src={subjectgeo} alt="" width={24} /></div>
                                                <div style={{ lineHeight: '28px' }}>&nbsp;&nbsp;地理</div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </>}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder='地理分数' type={'number'} />
                                    </Form.Item>
                                </Grid.Item>
                            </Grid>
                        </div>
                        <Grid columns={1}>
                            <Grid.Item style={{ padding:'0px',marginTop:'5px' }}>
                                <Button block type='submit' color='primary' size='large'>
                                    提交
                                </Button>
                            </Grid.Item>
                        </Grid>
                    </Form>
                </div>
            </div>
        </>
    }
}