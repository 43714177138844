import React, { Component } from 'react';
import { NavBar } from 'antd-mobile';
import { axiosPost } from '../../Utils/RequestUtil'
import styles from './index.module.css'
import pageback from '../../assets/images/homeImg/pageback.png'
export default class NewsList extends Component {
    constructor() {
        super()
        this.bodyRef = React.createRef();
        this.state = {
            index: 0,
            size: 10,
            newsData: [],
            dataLoadingCompleted: 0
        }
    }

    componentDidMount() {
        this.getNewsList()
        window.addEventListener('scroll', this.handleScroll, false)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false)
        this.setState = () => {
            return;
        };
    }

    handleScroll = () => {
        if (this.bodyRef.current.clientHeight === window.scrollY + window.innerHeight) {
            if (this.state.dataLoadingCompleted === 0) {
                this.setState({
                    index: this.state.index + 1
                }, () => {
                    this.getNewsAdd()
                }
                )
            }
        }
    }

    getNewsAdd = () => {
        const { index, size } = this.state
        const params = {
            key: '',
            index: index,
            size: size
        };
        axiosPost(6, params, 'University', (res) => {
            if(res.code===0){
                this.setState({
                    newsData: [...this.state.newsData, ...res.data.list],
                    dataLoadingCompleted: res.code
                })
            }
        })
    }

    getNewsList = () => {
        const { index, size } = this.state
        const params = {
            key: '',
            index: index,
            size: size
        };
        axiosPost(6, params, 'University', (res) => {
            if (res.code === 0) {
                this.setState({
                    newsData: res.data.list,
                    dataLoadingCompleted: res.code
                })
            }
        })

    }

    goNews = (item) => {
        this.props.history.push({ pathname: '/news', query: { newsKey: item.url, newsTitle: item.title } })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    render() {
        const { newsData } = this.state
        return (
            <div>
                <NavBar
                    style={{ backgroundColor: '#449bfe', color: '#fff', height: '50px', lineHeight: '50px', borderColor: '#449bfe' }}
                    onBack={this.goback}
                >
                    资讯列表
                </NavBar>
                <div style={{ padding: 10, backgroundColor: '#fff', height: window.innerHeight-50+ 'px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0',overflow:'auto' }}>
                    {newsData.length > 0 ? newsData.map(item =>
                        <div className={styles.dynamicItem} key={item.key} onClick={() => this.goNews(item)}>
                            <div className={styles.itemTitle}>
                                <span className={styles.schoolName}>{item.universityText}</span>
                                <span className={styles.time}>发布时间: {item.time}</span>
                            </div>
                            <div className={styles.dynamicContent}>
                                <div className={styles.contentInfo}>
                                    <p>{item.title}</p>
                                    <span>{item.desc}</span>
                                </div>
                                <div className={styles.contentImgBox}>
                                    <img src={item.image} alt='' />
                                </div>
                            </div>
                        </div>
                    ) : <div className={styles.nodata}>暂无数据</div>}
                </div>
            </div>
        )
    }
}