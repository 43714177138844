import React, { Component } from 'react';
import styles from './index.module.css'

import AllMajorBK from '../AllMajorBK/index.js'
import AllMajorZK from '../AllMajorZK/index.js'

import gobackImg from '../../assets/images/collegeInquiryImg/goback.png'
import underline from '../../assets/images/schoolDetailImg/underline.png'
import searchImg from '../../assets/images/homeImg/search.png'

export default class AllMajor extends Component {
    constructor() {
        super()
        this.state = {
            activeKey: '',

        }
    }
    componentDidMount() {
        this.setState({
            activeKey: 'BK'
        })
    }

    tabsClick = (decide) => {
        this.setState({
            activeKey: decide
        })
    }

    goback=()=>{
        this.props.history.go(-1)
    }
    goSearch=()=>{
        this.props.history.push('/search')
    }

    render() {
        const { activeKey } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.father}>
                    <div className={styles.headerBgc}>
                        <div className={styles.inputBox}>
                            <div className={styles.gobackImgBox} onClick={this.goback}>
                                <img src={gobackImg} alt='' />
                            </div>
                            <div className={styles.input} onClick={this.goSearch}>
                                <img src={searchImg} alt='' />
                                <input placeholder='请输入关键字进行搜索' />
                            </div>
                        </div>
                    </div>
                    <div className={styles.tabsBox}>
                        <ul>
                            <li onClick={() => this.tabsClick('BK')}>
                                <span>本科</span>
                                <img alt="" src={underline} style={{ display: activeKey === 'BK' ? 'block' : 'none' }}></img>
                            </li>
                            <li onClick={() => this.tabsClick('ZK')}>
                                <span>大专</span>
                                <img alt="" src={underline} style={{ display: activeKey === 'ZK' ? 'block' : 'none' }}></img>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.childPages}>
                    {
                        activeKey === 'BK' ? <AllMajorBK history={this.props.history}></AllMajorBK> :
                            activeKey === 'ZK' ? <AllMajorZK history={this.props.history}></AllMajorZK> : ''
                    }
                </div>
            </div>
        )
    }
}