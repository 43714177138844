import React, { Component } from 'react';
import styles from './index.module.css'

import gobackImg from '../../assets/images/collegeInquiryImg/goback.png'

export default class Website extends Component {
    constructor(props) {
        super(props)
        this.state = {
            url: '',
            name: '',
            contentHeight: 0
        }
    }

    componentDidMount() {
        if(this.props.location.query!==undefined){
            const {url,name} = this.props.location.query
            sessionStorage.setItem('universityUrl',url)
            sessionStorage.setItem('universityName',name)
            this.setState({
                url:this.props.location.query.url,
                name:this.props.location.query.name
            })
        }else {
            const universityUrl = sessionStorage.getItem('universityUrl')
            const universityName = sessionStorage.getItem('universityName')
            this.setState({
                url:universityUrl,
                name:universityName
            })
        }
        const contentHeight = window.innerHeight - 50
        this.setState({
            contentHeight: contentHeight
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    render() {
        const { url, contentHeight, name } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.header}>
                    <div className={styles.gobackBox} onClick={this.goback}>
                        <img src={gobackImg} alt='' />
                    </div>
                    <span>{name}</span>
                </div>
                <div style={{ height: contentHeight }}>
                    <iframe src={url} title='website' style={{ width: '100%', height: '100%' }}></iframe>
                </div>
            </div>
        )
    }
}