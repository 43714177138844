import axios from 'axios';
import { sign } from './SecurityUtil';

const version = '1.0';
const base = 'http://47.108.63.75:41712/api/';
const config = { showLoading: true }


export async function axiosPost(action, params, api, callback) {
  let body = {
    action: action,
    parameters: params
  };
  return axios.post(`${base}/${api}`, body, config).then(res => {
    callback(res.data);
  });
}
export async function axiosPostWithToken(action, token, params, api, callback) {
  let longTime = new Date().getTime().toString().substring(0, 10);
  let body = {
    action: action,
    parameters: params
  };
  axios.create({
    headers: {
      'content-type': 'application/json'
    }
  });
  body.time = longTime;
  body.sign = sign(body, longTime);
  body.version = version;
  axios.defaults.headers.token = token;
  let host = window.location.host;
  return axios.post(`${base}/${api}`, body, config).then(res => {
    if (res.data.code === 6) {
      window.location.href = 'http://' + host;
    } else {
      callback(res.data);
    }
  });
}
export async function axiosPostPay(action, token, params, api, callback) {
  let longTime = new Date().getTime().toString().substring(0, 10);
  let body = {
    action: action,
    parameters: params
  };
  axios.create({
    headers: {
      'content-type': 'application/json'
    }
  });
  axios.defaults.headers.time = longTime;
  axios.defaults.headers.sign = sign(body, longTime);
  axios.defaults.headers.version = version;
  axios.defaults.headers.token = token;
  let host = window.location.host;
  return axios.post(`http://pay.jhxhzn.com/api/${api}`, body, config).then(res => {
    if (res.data.code === 6) {
      window.location.href = 'http://' + host;
    } else {
      callback(res.data);
    }
  });
}

export async function axiosPut(action, token, params, api, callback) {
  let longTime = new Date().getTime().toString().substring(0, 10);
  let body = params;
  axios.create({
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  axios.defaults.headers.time = longTime;
  axios.defaults.headers.sign = sign(body, longTime);
  axios.defaults.headers.version = version;
  axios.defaults.headers.token = token;
  let host = window.location.host;
  return axios.put(`${base}/${api}`, body, config).then(res => {
    if (res.data.code === 6) {
      window.location.href = 'http://' + host;
    } else {
      callback(res.data);
    }
  });
} 