import React, { Component } from 'react';
import styles from './index.module.css'

import locationImg from '../../assets/images/collegeInquiryImg/location.png'
import rankImg from '../../assets/images/collegeInquiryImg/rank.png'
import categoryImg from '../../assets/images/collegeInquiryImg/category.png'

export default class SearchData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchData: this.props.searchData,
            isOpen: false,
            specialities: [],
            universities: [],
            contentWidth:0
        }
    }

    componentDidMount() {
        const searchData = this.props.searchData
        const contentWidth = window.screen.availWidth
        this.setState({
            contentWidth:contentWidth
        })
        if (searchData.specialities) {
            this.setState({
                specialities: searchData.specialities
            })
        }
        if (searchData.universities) {
            this.setState({
                universities: searchData.universities
            })
        }

    }

    isOpenChange = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    schoolInfo = (item) => {
        this.props.history.push(`/schoolInfo/${item.key}/${item.name}/${'1'}`)
    }
    majorClick = (item) => {
        this.props.history.push(`/majorDetail/${item.code}/${item.name}`)
    }

    render() {
        const { specialities, universities, isOpen ,contentWidth} = this.state
        return (
            <div className={styles.body}>
                <div className={styles.aboutMajor}>
                    <h2 className={styles.aboutMajorTitle}>相关专业</h2>
                    <h2 className={styles.aboutTotal}>{specialities.length > 0 ? `搜索到${specialities.length}条专业数据` : '暂无相关专业数据'}</h2>
                    <div className={isOpen ? styles.majorBoxOpen : styles.majorBox}>
                        {
                            specialities ?
                                specialities.map(item =>
                                    <span key={item.key} className={styles.majorItem} onClick={() => this.majorClick(item)}>{item.name}</span>
                                )
                                : ''
                        }
                    </div>
                    {specialities.length > 0 ?
                        <div className={styles.openAll} onClick={this.isOpenChange}>{isOpen ? '收起' : '展开全部'}</div> : ''
                    }
                </div>
                <div className={styles.aboutUniversity}>
                    <h2 className={styles.aboutUniversityTitle}>相关学校</h2>
                    <div className={styles.universityBox}>
                        <h2 className={styles.aboutTotal}>{universities.length > 0 ? `搜索到${universities.length}所大学` : '暂无相关大学数据'}</h2>
                        <ul>
                            {universities ? universities.map(item =>
                                <li onClick={() => this.schoolInfo(item)} key={item.key}>
                                    <div className={styles.allClude}>
                                        <div className={styles.flexLeft}>
                                            <div className={styles.logoBox}>
                                                <img src={item.logo} alt='' />
                                            </div>
                                            <div className={styles.schoolInfo} style={{width:contentWidth-160}}>
                                                <div className={styles.titleAndRank}>
                                                    <span style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>{item.name}</span>
                                                    <div className={styles.schoolRank}>
                                                        <img src={rankImg} alt='' />
                                                        <span>{item.rank}</span>
                                                    </div>
                                                </div>
                                                <div className={styles.schoolTags}>
                                                    {item.tagsText.map(child => <span key={child}>{child}</span>)}
                                                </div>
                                                <div className={styles.schoolCateLocal}>
                                                    <span><img src={categoryImg} alt='' />{item.categoryText}</span>
                                                    <span><img src={locationImg} alt='' />{item.provinceText+item.cityText}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ) : ''}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}