/* eslint-disable */
import React, { Component } from 'react';
import styles from './index.module.css'
import gobackImg from '../../assets/images/collegeInquiryImg/goback.png'
import pageback from '../../assets/images/homeImg/pageback.png'
export default class News extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newsUrl:'',
            title: '',
            contentHeight: 0
        }
    }

    componentDidMount() {
        if (this.props.location.query !== undefined) {
            const { newsKey, newsTitle } = this.props.location.query
            sessionStorage.setItem('newsKey', newsKey)
            sessionStorage.setItem('newsTitle', newsTitle)
            this.setState({
                newsUrl: this.props.location.query.newsKey,
                title: this.props.location.query.newsTitle
            })
        } else {
            const newsKey = sessionStorage.getItem('newsKey')
            const newsTitle = sessionStorage.getItem('newsTitle')
            this.setState({
                newsUrl: newsKey,
                title: newsTitle
            })
        }

        const contentHeight = window.innerHeight - 50
        this.setState({
            contentHeight: contentHeight
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    render() {
        const { newsUrl, contentHeight, title } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.header}>
                    <div className={styles.gobackBox} onClick={this.goback}>
                        <img src={gobackImg} alt='' />
                    </div>
                    <span><marquee>{title}</marquee></span>
                </div>
                <div style={{ height: contentHeight,backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0', overflow: 'auto'  }}>
                    <iframe src={newsUrl} title='news' style={{ width: '100%', height: '100%',border:'0px solid #ccc' }}></iframe>
                </div>
            </div>
        )
    }
}