import React, { Component } from 'react';
import { NavBar, Button, Form, Grid, Picker, Tag, Input, Toast } from 'antd-mobile';
import { CloseOutline, SearchOutline, AddOutline } from 'antd-mobile-icons'
import { axiosPost } from '../../Utils/RequestUtil';
import pageback from '../../assets/images/homeImg/pageback.png'
export default class Set extends Component {
    constructor() {
        super()
        this.state = {
            conHeight: window.innerHeight - 119,
            provinceData: [],
            speData: [],
            unvCateData: [],
            speCateData: [],
            proVis: false,
            speVis: false,
            unvCateVis: false,
            speCateVis: false,
            selectedProvince: [],
            selectSpe: [],
            selectUnvCate: [],
            selectSpeCate: [],
            keyword: null,
        }
    }
    componentDidMount() {
        this.getProvince();
    }
    getProvince = () => {
        const params = {
            flag: 'U-XZQH'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.code === 0) {
                let newProvinceData = [];
                res.data.list.forEach(item => {
                    if (item.CodeALLID !== '') {
                        item.label = item.CodeAllName;
                        item.value = item.CodeALLID;
                        newProvinceData.push(item);
                    }
                });
                this.setState({
                    provinceData: newProvinceData
                }, () => {
                    this.getUnvCate();
                });
            }
        })

    }
    getUnvCate = () => {
        const params = {
            flag: 'U-XXFL'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.code === 0) {
                let newdata = [];
                res.data.list.forEach(item => {
                    if (item.CodeALLID !== '') {
                        item.label = item.CodeAllName;
                        item.value = item.CodeALLID;
                        newdata.push(item);
                    }
                });
                this.setState({
                    unvCateData: newdata
                }, () => {
                    this.getSpeCate();
                });
            }
        })
    }
    getSpeCate = () => {
        let params = {
            flag: 'U-BKZY'
        }
        axiosPost(11, params, 'Data', (res) => {
            if (res.code === 0) {
                let newdata = [];
                res.data.list.forEach(item => {
                    if (item.CodeALLID !== '') {
                        item.label = item.CodeAllName;
                        item.value = item.CodeALLID;
                        newdata.push(item);
                    }
                });
                params = {
                    flag: 'U-ZKZY'
                }
                axiosPost(11, params, 'Data', (res) => {
                    if (res.code === 0) {
                        res.data.list.forEach(item => {
                            if (item.CodeALLID !== '') {
                                item.label = item.CodeAllName;
                                item.value = item.CodeALLID;
                                newdata.push(item);
                            }
                        });
                    }
                    this.setState({
                        speCateData: newdata
                    }, () => {
                        this.getData();
                    });
                })
            }
        })
    }
    getData = () => {
        const openId = sessionStorage.getItem('openId');
        const params = {
            user: openId
        }
        axiosPost(9, params, 'University', (res) => {
            if (res.code === 0) {
                const provinceData = res.data.ProvinceData;
                const specialityData = res.data.SpecialitiesRelationData;
                const unvCateData = res.data.UnvCateData;
                const speCateData = res.data.SpeCateData;
                let newProvinceData = [];
                provinceData.forEach(item => {
                    item.value = item.CodeAllID;
                    item.label = item.CodeName;
                    newProvinceData.push(item);
                });
                let newSpeData = [];
                specialityData.forEach(item => {
                    item.value = item.speciality;
                    item.label = item.name;
                    newSpeData.push(item);
                });
                let newUnvCateData = [];
                unvCateData.forEach(item => {
                    item.value = item.CodeAllID;
                    item.label = item.CodeName;
                    newUnvCateData.push(item);
                });

                let newSpeCateData = [];
                speCateData.forEach(item => {
                    item.value = item.CodeAllID;
                    item.label = item.CodeName;
                    newSpeCateData.push(item);
                });
                this.setState({ selectedProvince: newProvinceData, selectSpe: newSpeData, selectUnvCate: newUnvCateData, selectSpeCate: newSpeCateData });
                return;
            }
            this.setState({ selectedProvince: [], selectSpe: [], selectUnvCate: [], selectSpeCate: [] });
            return;
        })
    }
    goback = () => {
        this.props.history.go(-1)
    }
    onAddFinish = (values) => {
        const openId = sessionStorage.getItem('openId');
        const body = {
            RelKey: '',
            UserKey: openId,
            ProvinceList: this.state.selectedProvince.map(p => { return p.value }).join(','),
            SpecialitiesRelation: this.state.selectSpe.map(s => { return s.value }).join(','),
            UnSpecialitiesRelation: '',
            UnvCate: this.state.selectUnvCate.map(s => { return s.value }).join(','),
            SpeCate: this.state.selectSpeCate.map(s => { return s.value }).join(',')
        };
        Toast.clear();
        Toast.show({
            icon: 'loading',
            content: '提交中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(23, body, 'University', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.goback();
            } else {
                Toast.show({
                    icon: 'fail',
                    content: res.message,
                })
            }
        })
    }
    onProvinceChange = (value) => {
        let old = this.state.selectedProvince;
        let exist = false;
        old.forEach(o => {
            if (o.value === value[0]) {
                exist = true;
            }
        });
        if (!exist) {
            old.push(this.state.provinceData.filter(p => p.value === value[0])[0]);
        }
        this.setState({ selectedProvince: old })
    }
    onRemoveProvince = (pitem) => {
        let old = this.state.selectedProvince.filter(p => p.value !== pitem.value);
        this.setState({ selectedProvince: old })
    }

    onUnvCateChange = (value) => {
        let old = this.state.selectUnvCate;
        let exist = false;
        old.forEach(o => {
            if (o.value === value[0]) {
                exist = true;
            }
        });
        if (!exist) {
            old.push(this.state.unvCateData.filter(p => p.value === value[0])[0]);
        }
        this.setState({ selectUnvCate: old })
    }
    onRemoveUnvCate = (pitem) => {
        let old = this.state.selectUnvCate.filter(p => p.value !== pitem.value);
        this.setState({ selectUnvCate: old })
    }

    onSpeCateChange = (value) => {
        let old = this.state.selectSpeCate;
        let exist = false;
        old.forEach(o => {
            if (o.value === value[0]) {
                exist = true;
            }
        });
        if (!exist) {
            old.push(this.state.speCateData.filter(p => p.value === value[0])[0]);
        }
        this.setState({ selectSpeCate: old })
    }
    onRemoveSpeCate = (pitem) => {
        let old = this.state.selectSpeCate.filter(p => p.value !== pitem.value);
        this.setState({ selectSpeCate: old })
    }

    onSeachClick = () => {
        if (this.state.keyword === null || this.state.keyword === '') {
            Toast.clear();
            Toast.show('请输入专业名称');
            return;
        }
        const params = {
            keyword: this.state.keyword,
        }
        Toast.clear();
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        axiosPost(7, params, 'University', (res) => {
            Toast.clear()
            if (res.code === 0 && res.data.specialities.length > 0) {
                let speData = [];
                res.data.specialities.forEach(item => {
                    if (item.key !== '') {
                        item.label = item.name;
                        item.value = item.key;
                        speData.push(item);
                    }
                });
                this.setState({
                    speData: speData
                }, () => { this.setState({ speVis: true }) });
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未找到相关专业',
                })
            }
        })
    }
    onKeywordChange = (v) => {
        this.setState({ keyword: v });
    }
    onSpeChange = (value) => {
        let old = this.state.selectSpe;
        let exist = false;
        old.forEach(o => {
            if (o.value === value[0]) {
                exist = true;
            }
        });
        if (!exist) {
            old.push(this.state.speData.filter(p => p.value === value[0])[0]);
        }
        this.setState({ selectSpe: old })
    }
    onRemoveSpe = (pitem) => {
        let old = this.state.selectSpe.filter(p => p.value !== pitem.value);
        this.setState({ selectSpe: old })
    }
    render() {
        return <div>
            <NavBar
                style={{ backgroundColor: '#449bfe', color: '#fff', height: '50px', lineHeight: '50px', borderColor: '#449bfe' }}
                onBack={this.goback}
            >
                偏好设置
            </NavBar>
            <Form
                name='form'
                onFinish={this.onAddFinish}
                border={false}
                style={{
                    '--border-inner': '0px solid #ccc',
                    '--border-top': '0px solid #ccc',
                    '--border-bottom': '0px solid #ccc',
                }}
            >
                <div style={{ padding: 10, backgroundColor: '#f0f0f0', minHeight: this.state.conHeight + 'px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                    <Grid columns={1} style={{ marginTop: '15px' }}>
                        <Grid.Item style={{ border: '0px solid #ddd', backgroundColor: '#fff', marginTop: '5px', borderRadius: '10px', padding: '5px', boxShadow: '00px 10px 5px #ddd' }}>
                            <Form.Item
                                name='province'
                                trigger='onConfirm'
                                layout='vertical'
                                label={
                                    <Grid columns={2} gap={5}>
                                        <Grid.Item style={{ textAlign: 'left', lineHeight: '28px' }}>
                                            喜欢的省份
                                        </Grid.Item>
                                        <Grid.Item style={{ textAlign: 'right' }}>
                                            <Button shape='rounded' color='primary' style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', '--background-color': '#449bfe', '--border-color': '#449bfe', '--text-color': '#fff' }} onClick={() => { this.setState({ proVis: true }) }} size={'small'}><AddOutline />&nbsp;&nbsp;添加</Button>
                                        </Grid.Item>
                                    </Grid>
                                }
                                rules={[{ required: false, message: '请选择省份' }]}
                            >
                                {this.state.selectedProvince.length > 0
                                    ? this.state.selectedProvince.map(p => {
                                        return <>
                                            <Tag color='#999' style={{ paddingTop: 5, paddingBottom: 5, borderRadius: '10px', marginTop: '2px' }}>
                                                {p.label}&nbsp;&nbsp;
                                                <CloseOutline onClick={() => { this.onRemoveProvince(p) }} />
                                            </Tag>&nbsp;&nbsp;
                                        </>
                                    })
                                    : <div style={{ fontSize: '12px', color: '#999' }}>未设置喜欢的省份</div>}
                                {
                                    this.state.provinceData.length > 0 ? <Picker
                                        visible={this.state.proVis}
                                        columns={[this.state.provinceData]}
                                        onConfirm={(value) => { this.onProvinceChange(value) }}
                                        onClose={() => {
                                            this.setState({ proVis: false });
                                        }}
                                    /> : null
                                }
                            </Form.Item>
                        </Grid.Item>
                    </Grid>
                    <Grid columns={1} style={{ marginTop: '15px' }}>
                        <Grid.Item style={{ border: '0px solid #ddd', backgroundColor: '#fff', marginTop: '5px', borderRadius: '10px', padding: '5px', boxShadow: '00px 10px 5px #ddd' }}>
                            <Form.Item
                                name='province'
                                trigger='onConfirm'
                                layout='vertical'
                                label={<Grid columns={4} gap={5}>
                                    <Grid.Item style={{ textAlign: 'left', lineHeight: '28px' }}>
                                        喜欢的专业
                                    </Grid.Item>
                                    <Grid.Item span={2} style={{ textAlign: 'right' }}>
                                        <Input style={{ border: '1px solid #eee', width: '100%', padding: 5, '--font-size': '12px' }} onChange={(value) => { this.onKeywordChange(value) }} value={this.state.keyword} placeholder='请输入专业进行查找' />
                                    </Grid.Item>
                                    <Grid.Item style={{ textAlign: 'right' }}>
                                        <Button shape='rounded' style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', '--background-color': '#449bfe', '--border-color': '#449bfe', '--text-color': '#fff' }} onClick={() => { this.onSeachClick() }} size={'small'}><SearchOutline />&nbsp;&nbsp;查找</Button>
                                    </Grid.Item>
                                </Grid>
                                }
                                rules={[{ required: false, message: '请查找专业' }]}
                            >
                                {this.state.selectSpe.length > 0
                                    ? this.state.selectSpe.map(p => {
                                        return <>
                                            <Tag color='#999' style={{ paddingTop: 5, paddingBottom: 5, borderRadius: '10px', marginTop: '2px' }}>
                                                {p.label}&nbsp;&nbsp;
                                                <CloseOutline onClick={() => { this.onRemoveSpe(p) }} />
                                            </Tag>&nbsp;&nbsp;
                                        </>
                                    })
                                    : <div style={{ fontSize: '12px', color: '#999' }}>未设置喜欢的专业</div>}
                                {
                                    this.state.speData.length > 0 ? <Picker
                                        visible={this.state.speVis}
                                        columns={[this.state.speData]}
                                        onConfirm={(value) => { this.onSpeChange(value) }}
                                        onClose={() => {
                                            this.setState({ speVis: false });
                                        }}
                                    /> : null
                                }
                            </Form.Item>
                        </Grid.Item>
                    </Grid>
                    <Grid columns={1} style={{ marginTop: '15px' }}>
                        <Grid.Item style={{ border: '0px solid #ddd', backgroundColor: '#fff', marginTop: '5px', borderRadius: '10px', padding: '5px', boxShadow: '00px 10px 5px #ddd' }}>
                            <Form.Item
                                name='province'
                                trigger='onConfirm'
                                layout='vertical'
                                label={
                                    <Grid columns={2} gap={5}>
                                        <Grid.Item style={{ textAlign: 'left', lineHeight: '28px' }}>
                                            学校类别
                                        </Grid.Item>
                                        <Grid.Item style={{ textAlign: 'right' }}>
                                            <Button shape='rounded' color='primary' style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', '--background-color': '#449bfe', '--border-color': '#449bfe', '--text-color': '#fff' }} onClick={() => { this.setState({ unvCateVis: true }) }} size={'small'}><AddOutline />&nbsp;&nbsp;添加</Button>
                                        </Grid.Item>
                                    </Grid>
                                }
                                rules={[{ required: false, message: '请选择学校类别' }]}
                            >
                                {this.state.selectUnvCate.length > 0
                                    ? this.state.selectUnvCate.map(p => {
                                        return <>
                                            <Tag color='#999' style={{ paddingTop: 5, paddingBottom: 5, borderRadius: '10px', marginTop: '2px' }}>
                                                {p.label}&nbsp;&nbsp;
                                                <CloseOutline onClick={() => { this.onRemoveUnvCate(p) }} />
                                            </Tag>&nbsp;&nbsp;
                                        </>
                                    })
                                    : <div style={{ fontSize: '12px', color: '#999' }}>未设置喜欢的学校类别</div>}
                                {
                                    this.state.unvCateData.length > 0 ? <Picker
                                        visible={this.state.unvCateVis}
                                        columns={[this.state.unvCateData]}
                                        onConfirm={(value) => { this.onUnvCateChange(value) }}
                                        onClose={() => {
                                            this.setState({ unvCateVis: false });
                                        }}
                                    /> : null
                                }
                            </Form.Item>
                        </Grid.Item>
                    </Grid>
                    <Grid columns={1} style={{ marginTop: '15px' }}>
                        <Grid.Item style={{ border: '0px solid #ddd', backgroundColor: '#fff', marginTop: '5px', borderRadius: '10px', padding: '5px', boxShadow: '00px 10px 5px #ddd' }}>
                            <Form.Item
                                name='province'
                                trigger='onConfirm'
                                layout='vertical'
                                label={
                                    <Grid columns={2} gap={5}>
                                        <Grid.Item style={{ textAlign: 'left', lineHeight: '28px' }}>
                                            专业类别
                                        </Grid.Item>
                                        <Grid.Item style={{ textAlign: 'right' }}>
                                            <Button shape='rounded' color='primary' style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', '--background-color': '#449bfe', '--border-color': '#449bfe', '--text-color': '#fff' }} onClick={() => { this.setState({ speCateVis: true }) }} size={'small'}><AddOutline />&nbsp;&nbsp;添加</Button>
                                        </Grid.Item>
                                    </Grid>
                                }
                                rules={[{ required: false, message: '请选择专业类别' }]}
                            >
                                {this.state.selectSpeCate.length > 0
                                    ? this.state.selectSpeCate.map(p => {
                                        return <>
                                            <Tag color='#999' style={{ paddingTop: 5, paddingBottom: 5, borderRadius: '10px', marginTop: '2px' }}>
                                                {p.label}&nbsp;&nbsp;
                                                <CloseOutline onClick={() => { this.onRemoveSpeCate(p) }} />
                                            </Tag>&nbsp;&nbsp;
                                        </>
                                    })
                                    : <div style={{ fontSize: '12px', color: '#999' }}>未设置喜欢的专业类别</div>}
                                {
                                    this.state.speCateData.length > 0 ? <Picker
                                        visible={this.state.speCateVis}
                                        columns={[this.state.speCateData]}
                                        onConfirm={(value) => { this.onSpeCateChange(value) }}
                                        onClose={() => {
                                            this.setState({ speCateVis: false });
                                        }}
                                    /> : null
                                }
                            </Form.Item>
                        </Grid.Item>
                    </Grid>
                </div>
                <div style={{ backgroundColor: '#f0f0f0', height: '70px', padding: '10px' }}>
                    <Button block type='submit' color='primary' size='large'>
                        提交
                    </Button>
                </div>
            </Form>
        </div>
    }
}