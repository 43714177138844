import React, { Component } from 'react';

import goback from '../../assets/images/collegeInquiryImg/goback.png'

export default class FullMap extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        let address = ['116.404', '39.915']
        if(this.props.match.params.position!==''){
            address = this.props.match.params.position.split(',')
        }
        var map = new window.BMapGL.Map("container");
        var point = new window.BMapGL.Point(parseFloat(address[0]), parseFloat(address[1]));
        var marker = new window.BMapGL.Marker(point)
        var scaleCtrl = new window.BMapGL.ScaleControl()
        var zoomCtrl = new window.BMapGL.ZoomControl(); 
        map.addControl(scaleCtrl);
        map.addControl(zoomCtrl);   
        map.addOverlay(marker);
        map.centerAndZoom(point, 15);
    }

    goback=()=>{
        this.props.history.go(-1)
    }

    render() {
        return (
            <div style={{ width: "100%", height: '100%', position: 'relative' }}>
                <div style={{ position: 'absolute', left: '0', top: '0', zIndex: '999',height:'40px',width:'40px',textAlign:'center',paddingTop:'9px' }} onClick={this.goback}>
                    <img src={goback} alt='' style={{height:'22px'}} />
                </div>
                <div id="container" style={{ width: "100%", height: '100%' }}>

                </div>
            </div>
        )
    }
}