import React, { Component } from 'react';
import { Empty, NavBar, Toast, Card, Grid, Tag, Divider, Space } from 'antd-mobile';
import { Canvas, Chart, Line, Axis, Tooltip, Point } from '@antv/f2';
import { DownOutline, UpOutline, DownlandOutline } from 'antd-mobile-icons'
import { axiosPost } from '../../Utils/RequestUtil';
import styles from './index.module.css';
import pageback from '../../assets/images/homeImg/pageback.png'
const height = window.innerHeight;
export default class AchievementReoprt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.location.state,
            index: 0,
            size: 10000,
            detail: null,
            content: <></>,
            tabHeight: window.innerHeight - 251,
            reports: [],
            item: null,
            downicon: true,
            chartcontent: <></>,
            activeKey: '',
            url: ''
        }
    }
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        const openId = sessionStorage.getItem('openId');
        const body = {
            RelKey: '',
            UserKey: openId,
            Type: this.state.data === null ? '0' : '1',
            AchievementKey: this.state.data === null ? '' : this.state.data.Id
        };
        axiosPost(17, body, 'University', (res) => {
            Toast.clear();
            if (res.code === 0) {
                this.setState({ reports: res.data.list, url: res.data.Url });
                return;
            }
            this.setState({ reports: [] })
            return;
        })
    }
    goback = () => {
        this.props.history.go(-1)
    }
    onItemClick = (item) => {
        this.props.history.push(`/schoolInfo/${item.key}/${item.name}/${item.batch}`)
    }
    onChartClick = (item, type) => {
        let chartType = type === null ? 'Lowest' : type;
        let downicon = true;
        if (type === null) {
            if (this.state.item !== null) {
                if (item.batch + item.key === this.state.item.batch + this.state.item.key) {
                    downicon = !this.state.downicon
                }
                else {
                    downicon = false;
                    chartType = type === null ? 'Lowest' : type;
                }
            }
            else {
                downicon = false;
            }
        } else {
            downicon = false;
        }
        if (!downicon) {
            this.setState({ downicon: downicon, item: item, activeKey: chartType + item.batch + item.key }, () => {
                const context = document.getElementById('chartcon').getContext('2d');
                let newData = [];
                item.UniversityChatList.forEach(dataItem => {
                    dataItem[chartType] = dataItem[chartType] !== '' ? parseInt(dataItem[chartType]) : 0;
                    newData.push(dataItem);
                })
                const LineChart = (
                    <Canvas context={context} pixelRatio={window.devicePixelRatio}>
                        <Chart data={newData}>
                            <Axis
                                field="Year"
                                tickCount={5}
                                style={{
                                    label: { align: 'between' },
                                }}
                            />
                            <Axis field={chartType} tickCount={5} />
                            <Line x="Year" y={chartType} shape="smooth" />
                            <Point x="Year" y={chartType} />
                            <Tooltip showCrosshairs />
                        </Chart>
                    </Canvas>
                );
                const chart = new Canvas(LineChart.props);
                this.setState({ chartcontent: chart.render() })
            })
        }
        else {
            this.setState({ downicon: downicon, item: item })
        }
    }
    onChartTypeClick = (item, type) => {
        this.setState({ activeKey: type + item.batch + item.key }, () => {
            this.onChartClick(item, type);
        })
    }
    onReportDownClick = () => {
        window.open(this.state.url);
    }
    render() {
        return <div className={styles.body}>
            <NavBar
                style={{ backgroundColor: '#449bfe', color: '#fff', height: '50px', lineHeight: '50px', borderColor: '#449bfe' }}
                right={
                    this.state.url !== '' ? <div onClick={() => { this.onReportDownClick() }} style={{ fontSize: '20px' }}>
                        <Space style={{ '--gap': '3px' }}>
                            <DownlandOutline />
                        </Space>
                    </div> : null
                }
                onBack={this.goback}>
                志愿报告
            </NavBar>
            <div style={{ padding: 10, backgroundColor: '#f0f0f0', height: height - 50 + 'px', overflow: 'auto', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                {
                    this.state.reports.length > 0 ? this.state.reports.map(item =>
                        <div style={{ marginBottom: '15px' }}>
                            <Card
                                key={item.key}
                                title={<><div style={{ float: 'left' }}>
                                    <img src={item.logo} style={{ height: '40px', width: '40px' }} alt="" />
                                </div><div style={{ float: 'left', lineHeight: '44px' }}>
                                        &nbsp;&nbsp;{item.name}
                                    </div></>}
                                style={{ border: '0px solid #ddd', borderRadius: '5px 5px 5px 5px',boxShadow: '0px 6px 5px #ddd' }}
                                extra={<div>
                                    {
                                        item.VoluntaryModel.Type === '冲刺'
                                            ? <Tag color='danger'>{item.VoluntaryModel.Type}</Tag>
                                            : item.VoluntaryModel.Type === '稳定'
                                                ? <Tag color='success'>{item.VoluntaryModel.Type}</Tag>
                                                : <Tag color='primary'>{item.VoluntaryModel.Type}</Tag>
                                    }
                                </div>}
                            >
                                <div onClick={() => { this.onItemClick(item) }}>
                                    <Grid columns={2} gap={0}>
                                        <Grid.Item style={{ fontSize: '14px' }}>
                                            {item.tagsText.map(tag => <><Tag color='#999' style={{ borderRadius: '10px', marginTop: '2px' }}>{tag}</Tag>&nbsp;&nbsp;</>)}
                                        </Grid.Item>
                                        <Grid.Item style={{ textAlign: 'right' }}>
                                            院校代码：<span style={{ fontWeight: 'bold' }}>{item.code}</span>
                                        </Grid.Item>
                                    </Grid>
                                    <Grid columns={1} style={{ marginTop: 10 }}>
                                        <Grid.Item style={{ textAlign: 'left' }}>
                                            <span style={{ fontWeight: 'bold' }}>录取批次：{item.batch}</span>
                                        </Grid.Item>
                                    </Grid>
                                    <Divider>近5年数据分析</Divider>
                                    <Grid columns={5} gap={5}>
                                        <Grid.Item>
                                            <Card style={{ backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                                                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>平均分</span><br /><br />
                                                <span style={{ fontSize: '14px' }}>{item.VoluntaryModel.FiveAdmission}</span><br />
                                            </Card>
                                        </Grid.Item>
                                        <Grid.Item>
                                            <Card style={{ backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                                                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>最高分</span><br /><br />
                                                <span style={{ fontSize: '14px' }}>{item.VoluntaryModel.FiveHighest}</span><br />
                                            </Card>
                                        </Grid.Item>
                                        <Grid.Item>
                                            <Card style={{ backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                                                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>录取位</span><br /><br />
                                                <span style={{ fontSize: '14px' }}>{item.VoluntaryModel.FiveRanking}</span><br />
                                            </Card>
                                        </Grid.Item>
                                        <Grid.Item>
                                            <Card style={{ backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                                                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>最高位</span><br /><br />
                                                <span style={{ fontSize: '14px' }}>{item.VoluntaryModel.FivehighestRank}</span><br />
                                            </Card>
                                        </Grid.Item>
                                        <Grid.Item>
                                            <Card style={{ backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                                                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>招生数</span><br /><br />
                                                <span style={{ fontSize: '14px' }}>{item.VoluntaryModel.FiveEnrollment}</span><br />
                                            </Card>
                                        </Grid.Item>
                                    </Grid>
                                </div>
                                <Grid columns={1} style={{ marginTop: 10 }}>
                                    <Grid.Item style={{ textAlign: 'center' }}>
                                        {
                                            this.state.item !== null && this.state.item.batch + this.state.item.key === item.batch + item.key && !this.state.downicon
                                                ? <div>
                                                    <div onClick={() => { this.onChartClick(item, null) }}>收起趋势图<UpOutline /></div>
                                                    <Grid columns={5} gap={5} style={{ marginTop: '10px' }}>
                                                        <Grid.Item>
                                                            <Card style={{ padding: 0 }} bodyStyle={{ padding: 3 }} className={this.state.activeKey === '' || this.state.activeKey === 'Lowest' + item.batch + item.key ? styles.activeCardTab : styles.normalCardTab} onClick={() => { this.onChartTypeClick(item, 'Lowest') }}>录取分</Card>
                                                        </Grid.Item>
                                                        <Grid.Item>
                                                            <Card style={{ padding: 0 }} bodyStyle={{ padding: 3 }} className={this.state.activeKey === 'Lowrank' + item.batch + item.key ? styles.activeCardTab : styles.normalCardTab} onClick={() => { this.onChartTypeClick(item, 'Lowrank') }}>录取位</Card>
                                                        </Grid.Item>
                                                        <Grid.Item>
                                                            <Card style={{ padding: 0 }} bodyStyle={{ padding: 3 }} className={this.state.activeKey === 'Highest' + item.batch + item.key ? styles.activeCardTab : styles.normalCardTab} onClick={() => { this.onChartTypeClick(item, 'Highest') }}>最高分</Card>
                                                        </Grid.Item>
                                                        <Grid.Item>
                                                            <Card style={{ padding: 0 }} bodyStyle={{ padding: 3 }} className={this.state.activeKey === 'Highrank' + item.batch + item.key ? styles.activeCardTab : styles.normalCardTab} onClick={() => { this.onChartTypeClick(item, 'Highrank') }}>最高位</Card>
                                                        </Grid.Item>
                                                        <Grid.Item>
                                                            <Card style={{ padding: 0 }} bodyStyle={{ padding: 3 }} className={this.state.activeKey === 'PlanCount' + item.batch + item.key ? styles.activeCardTab : styles.normalCardTab} onClick={() => { this.onChartTypeClick(item, 'PlanCount') }}>招生数</Card>
                                                        </Grid.Item>
                                                    </Grid>
                                                    <canvas id="chartcon" width={window.innerWidth - 50} height={200}>
                                                        {this.state.chartcontent}
                                                    </canvas>
                                                </div>
                                                : <div onClick={() => { this.onChartClick(item, null) }}>展开趋势图<DownOutline /></div>
                                        }

                                    </Grid.Item>
                                </Grid>
                            </Card>
                        </div>
                    ) : <Empty
                        style={{ padding: '64px 0' }}
                        imageStyle={{ width: 128 }}
                        description='暂无数据'
                    />
                }
            </div>
        </div>
    }
}