import React, { Component } from 'react';
import { Empty, NavBar, Toast, Button, TabBar, Space } from 'antd-mobile';
import { TextOutline } from 'antd-mobile-icons'
import { axiosPost } from '../../Utils/RequestUtil';
import styles from './index.module.css';
import styles1 from '../ScoreSchool/index.module.css';
import locationImg from '../../assets/images/collegeInquiryImg/location.png'
import rankImg from '../../assets/images/collegeInquiryImg/rank.png'
import categoryImg from '../../assets/images/collegeInquiryImg/category.png'
import maskimg from '../../assets/images/schoolDetailImg/masklist1.png'
import pageback from '../../assets/images/homeImg/pageback.png'
export default class AchievementDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.location.state,
            index: 0,
            size: 10000,
            detail: null,
            content: <></>,
            activeKey: '',
            tabHeight: window.innerHeight - 111,
        }
    }
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        const openId = sessionStorage.getItem('openId');
        const body = {
            RelKey: '',
            UserKey: openId,
            Index: this.state.index,
            Size: this.state.size,
            Type: '1',
            AchievementKey: this.state.data.Id
        };
        axiosPost(16, body, 'University', (res) => {
            Toast.clear();
            if (res.code === 0) {
                this.setState({ detail: res.data }, () => {
                    this.setContent('second')
                })
                return;
            }
            this.setState({ data: [] });
            return;
        })
    }
    goback = () => {
        this.props.history.go(-1)
    }
    schoolInfo = (item) => {
        this.props.history.push(`/schoolInfo/${item.key}/${item.name}/${item.batch}`)
    }
    setContent = (value) => {
        this.setState({ activeKey: value }, () => {
            const tabContentWidth = window.screen.availWidth
            this.setState({
                content: <div style={{ height: this.state.tabHeight + 'px', overflow: 'auto', backgroundColor: 'transparent' }}>
                    <ul style={{ backgroundColor: 'transparent' }}>
                        {
                            this.state.detail[value].list.length > 0 ? this.state.detail[value].list.map(item =>
                                <li onClick={() => this.schoolInfo(item)} key={item.key}>
                                    <div className={styles1.allClude}>
                                        <div className={styles1.flexLeft}>
                                            <div className={styles1.logoBox}>
                                                <img src={item.logo} alt='' />
                                            </div>
                                            <div className={styles1.schoolInfo} style={{ width: tabContentWidth - 160 }}>
                                                <div className={styles1.titleAndRank}>
                                                    <span style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>{item.name}</span>
                                                    <div className={styles1.schoolRank}>
                                                        <img src={rankImg} alt='' />
                                                        <span>{item.rank}</span>
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: '12px', marginBottom: 10, color: '#666' }}>
                                                    <span>批次：</span>
                                                    <span>{item.batch}</span>
                                                </div>
                                                <div className={styles1.schoolTags}>
                                                    {item.tagsText.map(child => <span key={child}>{child}</span>)}
                                                </div>
                                                <div className={styles1.schoolCateLocal}>
                                                    <span><img src={categoryImg} alt='' />{item.categoryText}</span>
                                                    <span><img src={locationImg} alt='' />{item.provinceText + item.cityText}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ) : <li style={{ textAlignLast: 'center', background: 'none' }}>未找到相关数据</li>}
                        {
                            !this.state.detail.IsCharge ? <li style={{ padding: 0 }}>
                                <div style={{ margin: 15, marginTop: 0, borderRadius: '10px', backgroundImage: 'url(' + maskimg + ')', backgroundSize: '100% 140px', backgroundPosition: '0px 0px', height: '140px', paddingTop: '0px', textAlign: 'center' }}>
                                    <Button color='danger' onClick={() => { this.onOrderClick() }} style={{ height: '40px', marginTop: 30 }}>
                                        立即订购
                                    </Button>
                                    <div style={{ lineHeight: '50px' }}>订购服务可查看更多推荐学校，享受更多服务</div>
                                </div>
                            </li> : null
                        }
                    </ul>
                </div>
            });
        })
    }
    onOrderClick = () => {
        this.props.history.push(`/achievementOrder`, this.state.data);
    }
    onReportClick = () => {
        if (this.state.detail.IsCharge) {
            this.props.history.push(`/achievementReport`, this.state.data);
        }
        else {
            this.props.history.push(`/achievementOrder`, this.state.data);
        }
    }
    render() {
        const tabs = [
            { title: '推荐', sub: '1', key: 'second' },
            { title: '冲刺', sub: '2', key: 'first' },
            { title: '保底', sub: '3', key: 'three' },
        ];
        const TabEle = () => (
            <TabBar activeKey={this.state.activeKey} onChange={value => this.setContent(value)} style={{ backgroundColor: '#469CFE', borderRadius: '50px 50px 0px 0px' }}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} style={{ color: item.key === this.state.activeKey ? '#469CFE' : '#fff' }} title={
                        <div style={{ fontSize: '14px', fontWeight: 'bold', padding: '10px', borderRadius: '20px 20px 20px 20px', backgroundColor: item.key === this.state.activeKey ? '#fff' : '#469CFE' }}>
                            {item.title}
                        </div>}
                    />
                ))}
            </TabBar>
        );
        return <div className={styles.body}>
            <NavBar
                style={{ backgroundColor: '#449bfe', color: '#fff', height: '50px', lineHeight: '50px', borderColor: '#449bfe' }}
                right={
                    <div onClick={() => { this.onReportClick() }} style={{ fontSize: '20px' }}>
                        <Space style={{ '--gap': '3px' }}>
                            <TextOutline /><span style={{ fontSize: '14px' }}>分析</span>
                        </Space>
                    </div>}
                onBack={this.goback}
            >
                智能推荐
            </NavBar>
            {
                this.state.detail !== undefined && this.state.detail !== null
                    ? <div style={{ padding: 0, backgroundColor: '#f0f0f0', minHeight: this.state.tabHeight + 'px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                        <div>
                            <div className={styles1.tabcontent}>
                                <div className={styles1.schoolList}>
                                    {this.state.content}
                                </div>
                                <div className={styles1.tabbottom}><TabEle /></div>
                            </div>
                        </div>
                    </div>
                    : <div style={{ padding: 0, backgroundColor: '#f0f0f0', minHeight: window.innerHeight - 51 + 'px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 100px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                        <Empty description='暂无数据' />
                    </div>
            }
        </div>
    }
}