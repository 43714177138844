import React, { Component } from 'react';
import styles from './index.module.css'

import majorIcon from '../../assets/images/schoolDetailImg/majorIcon.png'

export default class EnrollData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showData: this.props.enrollData,
            showDataTitle: this.props.admitBatchTitle,
            provinceTitle: '',
            wklkTitle: '',
            isShow: false,
            contentWidth: 0
        }
    }

    componentDidMount() {
        const provinceTitle = sessionStorage.getItem('provinceTitle')
        const wklkTitle = sessionStorage.getItem('wklkTitle')

        const contentWidth = window.screen.availWidth
        this.setState({
            provinceTitle: provinceTitle,
            wklkTitle: wklkTitle,
            contentWidth: contentWidth
        })
    }
    onOpenChange = () => {
        this.props.showSpring('admits')
    }


    render() {
        const { showDataTitle, showData, wklkTitle, provinceTitle, contentWidth } = this.state
        return (
            <div className={styles.body}>
                {showData.length === 0 ? <div className={styles.nodata}>暂无数据</div> :
                    <div>
                        <div className={styles.batchEnrollData}>
                            <span>{showDataTitle}</span>
                            <img src={majorIcon} alt='' onClick={this.onOpenChange} />
                            <div style={{marginTop:'5px',color:'red',fontSize:'12px'}}>当前院校可能有多个录取批次，点击上方切换</div>
                        </div>
                        <div className={styles.enrollData}>
                            <h2 className={styles.dataTitle}>{showDataTitle} ({provinceTitle}-{wklkTitle})</h2>
                            <div className={styles.dataBox}>
                                <div className={styles.dataItemTitle}>
                                    <ul>
                                        <li><span className={styles.itemTitle}>年度</span></li>
                                        <li><span className={styles.itemTitle}>录取批次</span></li>
                                        <li><span className={styles.itemTitle}>录取线</span></li>
                                        <li><span className={styles.itemTitle}>最高位次</span></li>
                                        <li><span className={styles.itemTitle}>最低位次</span></li>
                                        <li><span className={styles.itemTitle}>最高分</span></li>
                                        <li><span className={styles.itemTitle}>最低线差</span></li>
                                        <li><span className={styles.itemTitle}>最高线差</span></li>
                                        <li><span className={styles.itemTitle}>录取人数</span></li>
                                        <li><span className={styles.itemTitle}>投档人数</span></li>
                                        <li><span className={styles.itemTitle}>投档占比</span></li>
                                        <li><span className={styles.itemTitle}>省批次线</span></li>
                                    </ul>
                                </div>
                                <div className={styles.dataItemInfo} style={{ width: contentWidth - 100 }}>
                                    <ul style={{ width: showData.length * 120 }}>
                                        <li>
                                            {showData.map(item => <span key={item.key} style={{ fontWeight: '600' }}>{item.year}</span>)}
                                        </li>
                                        <li style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                                            {showData.map(item => <div key={item.key} className={styles.batchRow} style={{ mar: '0 3px' }}>{item.batch}</div>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.lowest}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.highrank===''||item.lowrank==='-1'?'/':item.highrank}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.lowrank===''||item.lowrank==='-1'?'/':item.lowrank}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.highest}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.lowestDifference}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.highestDifference}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.planCount}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.submitCount}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.submitPlanScale}</span>)}
                                        </li>
                                        <li>
                                            {showData.map(item => <span key={item.key}>{item.provinceLine}</span>)}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={styles.reference}>
                            数据仅供参考
                        </div>
                    </div>
                }
            </div>
        )
    }
}