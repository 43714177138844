import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import ChooseSchool from './pages/ChooseSchool/index.js'
import CollegeInquiry from './pages/CollegeInquiry/index.js'
import SchoolInfo from './pages/SchoolInfo/index.js'
import Search from './pages/Search/index.js'
import UserSetting from './pages/UserSetting/index.js'
import MajorDetail from './pages/MajorDetail/index.js'
import AllMajor from './pages/AllMajor/index.js'
import News from './pages/News/index.js'
import Website from './pages/Website/index.js'
import ScoreSchool from './pages/ScoreSchool/index.js'
import NewsList from './pages/NewsList/index.js'
import FullMap from './pages/FullMap/index.js'
import Achievement from './pages/Achievement/index.js';
import AchievementAdd from './pages/Achievement/add.js';
import AchievementDetail from './pages/Achievement/detail.js';
import AchievementOrder from './pages/Achievement/order.js';
import AchievementReoprt from './pages/Achievement/report.js';
import Set from './pages/UserSetting/set.js';

function App() {
  return (
    <Router>
      <div className="App" style={{ width: '100%', height: '100%' }}>
        <Route path='/' exact render={() => <Redirect to='/chooseSchool' />} />
        <Route path='/chooseSchool' component={ChooseSchool} />
        <Route path='/collegeInquiry' component={CollegeInquiry} />
        <Route path='/schoolInfo/:key/:name/:batch' component={SchoolInfo} />
        <Route path='/search' component={Search} />
        <Route path='/userSetting' component={UserSetting} />
        <Route path='/allMajorIntro' component={AllMajor} />
        <Route path='/majorDetail/:majorId/:majorName' component={MajorDetail} />
        <Route path='/news' component={News} />
        <Route path='/website' component={Website} />
        <Route path='/newsList' component={NewsList} />
        <Route path='/scoreSchool' component={ScoreSchool} />
        <Route path='/fullMap/:position' component={FullMap} />
        <Route path='/achievement' component={Achievement} />
        <Route path='/achievementAdd' component={AchievementAdd} />
        <Route path='/achievementDetail' component={AchievementDetail} />
        <Route path='/achievementOrder' component={AchievementOrder} />
        <Route path='/achievementReport' component={AchievementReoprt} />
        <Route path='/set' component={Set} />
      </div>
    </Router>
  );
}

export default App;
