import React, { Component } from 'react';
import { axiosPost } from '../../Utils/RequestUtil'
import { Toast, TabBar, Button, NavBar, Space, Empty } from 'antd-mobile'
import { TextOutline, BellOutline } from 'antd-mobile-icons'
import styles from './index.module.css'
import locationImg from '../../assets/images/collegeInquiryImg/location.png'
import rankImg from '../../assets/images/collegeInquiryImg/rank.png'
import categoryImg from '../../assets/images/collegeInquiryImg/category.png'
import maskimg from '../../assets/images/schoolDetailImg/masklist1.png'
import pageback from '../../assets/images/homeImg/pageback.png'

export default class ScoreSchool extends Component {
    constructor() {
        super()
        this.bodyRef = React.createRef();
        this.state = {
            contentWidth: 0,
            universityData: [],
            size: 100,
            index: 0,
            score: 0,
            province: '',
            type: '',
            dataLoadingCompleted: 0,
            rank: '-1',
            first: [],
            second: [],
            three: [],
            content: <></>,
            activeKey: '',
            IsCharge: false,
        }
    }

    componentDidMount() {
        const score = sessionStorage.getItem('score')
        const province = sessionStorage.getItem('provinceKey')
        const type = sessionStorage.getItem('wklkKey')
        const rank = sessionStorage.getItem('rank')
        const contentWidth = window.screen.availWidth
        const contentHeight = window.innerHeight - 50
        this.setState({
            contentHeight: contentHeight,
            contentWidth: contentWidth,
            province: province,
            type: type,
            score: score,
            rank: rank
        })
        this.getSchoolScore()
    }
    getSchoolScore = () => {
        Toast.show({
            icon: 'loading',
            content: '数据加载中',
            duration: 0,
            maskClickable: false
        })
        const { index, size } = this.state
        const openId = sessionStorage.getItem('openId');
        const body = {
            RelKey: '',
            UserKey: openId,
            Index: index,
            Size: size,
            Type: '0',
            AchievementKey: ''
        };
        axiosPost(16, body, 'University', (res) => {
            Toast.clear()
            if (res.code === 0) {
                this.setState({
                    universityData: res.data.list,
                    first: res.data.first.list,
                    second: res.data.second.list,
                    three: res.data.three.list,
                    IsCharge: res.data.IsCharge
                })
                this.setContent("universityData");
                return;
            }
            this.setState({
                universityData: [],
                first: [],
                second: [],
                three: [],
                IsCharge: false
            })
            this.setContent("universityData");
            return;
        })
    }

    schoolInfo = (item) => {
        this.props.history.push(`/schoolInfo/${item.key}/${item.name}/${item.batch}`)
    }

    goback = () => {
        this.props.history.go(-1)
    }
    setContent = (value) => {
        this.setState({ activeKey: value }, () => {
            const tabContentWidth = window.screen.availWidth
            const tabContentHeight = window.innerHeight - 133
            this.setState({
                content: <div style={{ height: tabContentHeight, overflow: 'auto', marginTop: 0, backgroundColor: 'transparent' }}><ul>
                    {
                        this.state[value].length > 0 ? this.state[value].map(item =>
                            <li onClick={() => this.schoolInfo(item)} key={item.key}>
                                <div className={styles.allClude}>
                                    <div className={styles.flexLeft}>
                                        <div className={styles.logoBox}>
                                            <img src={item.logo} alt='' />
                                        </div>
                                        <div className={styles.schoolInfo} style={{ width: tabContentWidth - 160 }}>
                                            <div className={styles.titleAndRank}>
                                                <span style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>{item.name}</span>
                                                <div className={styles.schoolRank}>
                                                    <img src={rankImg} alt='' />
                                                    <span>{item.rank}</span>
                                                </div>
                                            </div>
                                            <div style={{ fontSize: '12px', marginBottom: 10, color: '#666' }}>
                                                <span>批次：</span>
                                                <span>{item.batch}</span>
                                            </div>
                                            <div className={styles.schoolTags}>
                                                {item.tagsText.map(child => <span key={child}>{child}</span>)}
                                            </div>
                                            <div className={styles.schoolCateLocal}>
                                                <span><img src={categoryImg} alt='' />{item.categoryText}</span>
                                                <span><img src={locationImg} alt='' />{item.provinceText + item.cityText}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ) : <li style={{ textAlignLast: 'center', background: 'none' }}>未找到相关数据</li>}
                    {
                        !this.state.IsCharge ? <li style={{ padding: 0 }}>
                            <div style={{ margin: 15, marginTop: 0, borderRadius: '10px', backgroundImage: 'url(' + maskimg + ')', backgroundSize: '100% 140px', backgroundPosition: '0px 0px', height: '140px', paddingTop: '0px', textAlign: 'center' }}>
                                <Button color='danger' onClick={() => { this.onOrderClick() }} style={{ height: '40px', marginTop: 30 }}>
                                    立即订购
                                </Button>
                                <div style={{ lineHeight: '50px' }}>订购服务可查看更多推荐学校，享受更多服务</div>
                            </div>
                        </li> : null
                    }
                </ul></div>
            });
        })
    }
    onOrderClick = () => {
        this.props.history.push(`/achievementOrder`, null);
    }
    onReportClick = () => {
        if (this.state.IsCharge) {
            this.props.history.push(`/achievementReport`, null);
        }
        else {
            this.props.history.push(`/achievementOrder`, null);
        }
    }
    onSetClick = () => {
        this.props.history.push(`/set`);
    }
    render() {
        const tabs = [
            { title: '推荐', sub: '1', key: 'universityData' },
            { title: '冲刺', sub: '2', key: 'first' },
            { title: '保底', sub: '3', key: 'three' },
        ];
        const TabEle = () => (
            <TabBar activeKey={this.state.activeKey} onChange={value => this.setContent(value)} style={{ backgroundColor: '#469CFE', borderRadius: '50px 50px 0px 0px' }}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} style={{ color: item.key === this.state.activeKey ? '#469CFE' : '#fff' }} title={
                        <div style={{ fontSize: '14px', fontWeight: 'bold', padding: '10px', borderRadius: '20px 20px 20px 20px', backgroundColor: item.key === this.state.activeKey ? '#fff' : '#469CFE' }}>
                            {item.title}
                        </div>}
                    />
                ))}
            </TabBar>
        );
        return (
            <div className={styles.body} ref={this.bodyRef}>
                <NavBar
                    style={{ backgroundColor: '#449bfe', color: '#fff', height: '50px', lineHeight: '50px', borderColor: '#449bfe' }}
                    right={
                        <div onClick={() => { this.onReportClick() }} style={{ fontSize: '20px' }}>
                            <Space style={{ '--gap': '3px' }}>
                                <TextOutline /><span style={{ fontSize: '14px' }}>分析</span>
                            </Space>
                        </div>}
                    onBack={this.goback}
                >
                    智能推荐
                </NavBar>
                <div style={{ padding: 5, backgroundColor: '#449bfe', height: '22px', lineHeight: '16px', color: '#fff', textAlign: 'center' }}>
                    <BellOutline />&nbsp;有特别喜欢的省份或专业？<div style={{ display: 'inline', textDecoration: 'underline' }} onClick={() => { this.onSetClick() }} size='small' color='primary' fill='none'>点击设置</div>
                </div>
                {
                    (this.state.activeKey==='universityData'&&this.state.universityData.length)||(this.state.activeKey==='first'&&this.state.first.length)||(this.state.activeKey==='three'&&this.state.three.length) > 0
                        ? <div style={{ padding: 0, backgroundColor: '#f0f0f0', minHeight: window.innerHeight - 123 + 'px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 78px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                            <div className={styles.tabcontent}>
                                <div className={styles.schoolList}>
                                    {this.state.content}
                                </div>
                            </div>
                        </div>
                        : <div style={{ padding: 0, backgroundColor: '#f0f0f0', minHeight: window.innerHeight - 123 + 'px', backgroundImage: 'url(' + pageback + ')', backgroundSize: '100% 78px', backgroundRepeat: 'no-repeat', backgroundPosition: '0 0' }}>
                            <Empty description='暂无数据' />
                        </div>
                }
                <div className={styles.tabbottom} style={{backgroundColor: '#f0f0f0'}}><TabEle /></div>
            </div>
        )
    }
}